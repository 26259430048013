import { track } from "jiffy-analytics";
import { waitForWindowVariable } from "common/utils/ui/wait_for";
import { megabitsPerSecond } from "common/utils/helpers/upload-rate";
import { BUSINESS_LINE } from "../app-registry/contants";

// eslint-disable-next-line import/prefer-default-export
export const submitDtfEvent = (event, rawPayload = {}) => {
  const $getOrderNumberContainer = () => document.querySelector(".js-order-number-container");

  const getOrderNumber = () => $getOrderNumberContainer()?.dataset?.orderNumber;

  let payload = {
    order_number: getOrderNumber(),
    ...rawPayload,
    business_line:
      rawPayload && rawPayload.business_line ? rawPayload.business_line : BUSINESS_LINE.DTF
  };

  const catalogNumber = document.querySelector(".js-dtf-product")?.dataset?.catalogNumber;
  if (catalogNumber) {
    payload = { ...payload, catalog_number: catalogNumber };
  }

  const currentStoreCode = document.documentElement.lang === "en-CA" ? "ca" : "us";
  if (currentStoreCode) {
    payload = { current_store_code: currentStoreCode, ...payload };
  }

  // non-PDP or a previous dynamicDataLoaded already set the order number
  if (getOrderNumber()) {
    track(event, payload);
    return;
  }

  const $scriptElement = document.querySelector('script[src^="/api/pdp_dynamic_data"]');
  if ($scriptElement) {
    waitForWindowVariable(
      "dynamicDataLoaded",
      () => {
        track(event, payload);
      },
      () => {
        console.error("dynamicDataLoaded failed"); // eslint-disable-line no-console
        track(event, payload);
      }
    );
  }
};

/* eslint-disable max-lines-per-function, camelcase */
export const submitCompletedEvent = rawPayload => {
  const {
    file,
    artwork,
    catalogNumber,
    response,
    multiArtwork,
    pdpType,
    uploadedOn,
    uploadStartTime,
    validationErrors,
    businessLine,
    orderType
  } = rawPayload;

  if (!uploadStartTime) {
    throw new Error("uploadStartTime is required");
  }
  const uploadedFile = file || artwork?.file;
  const upload_speed_megabits_per_second = megabitsPerSecond(
    uploadedFile.size,
    uploadStartTime,
    performance.now()
  );

  const metadata = artwork?.metadata || {};
  const page = uploadedOn || metadata?.uploaded_on;
  const project_id = artwork?.project_id || response?.project_id;
  const image_url = artwork?.url;

  let validation_errors = validationErrors || artwork?.errors;
  if (!validation_errors && response?.error) {
    validation_errors = [response.error];
  }

  const pdp_type = pdpType;

  let multi_artwork = multiArtwork;
  if (multi_artwork === undefined) {
    multi_artwork = false;
  }

  const catalog_number = catalogNumber;
  const business_line = businessLine;
  const order_type = orderType;

  const data = {
    filetype: uploadedFile.type,
    filesize: uploadedFile.size,
    filename: uploadedFile.name,
    artwork_filetype: artwork?.filetype,
    artwork_filesize: artwork?.filesize
  };

  const payload = {
    data,
    upload_speed_megabits_per_second,
    metadata,
    catalog_number,
    page,
    pdp_type,
    validation_errors,
    multi_artwork,
    project_id,
    image_url,
    business_line,
    ...(order_type && { order_type })
  };

  submitDtfEvent("image_upload_completed", payload);
};
