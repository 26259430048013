/* eslint-disable import/prefer-default-export */
import { track } from "jiffy-analytics";

const $getBrandCheckbox = () => document.querySelector(".js-product-color-picker-hide-brand");
const $getMaterialCheckbox = () => document.querySelector(".js-product-color-picker-hide-material");
const $getPriceCheckbox = () => document.querySelector(".js-product-color-picker-hide-info");
const $getCozyFeelCheckbox = () => document.querySelector(".js-product-color-picker-hide-cozyfeel");

export const trackEvent = (event, itemElements, additionalProps = {}) => {
  if (!itemElements) return;
  const { $productItem, $lineItem } = itemElements;
  if (!$lineItem) return;

  const { gaPosition } = $productItem?.dataset ?? {};
  const {
    name,
    brand,
    category,
    compositionId,
    composition,
    pageName,
    fulfillmentGroup,
    itemPosition,
    businessLine
  } = $lineItem.dataset;

  track(event, {
    ...additionalProps,
    product: name,
    brand,
    product_category: category,
    spree_composition_id: compositionId,
    composition,
    fulfillment_group: fulfillmentGroup,
    location: pageName,
    item_position: itemPosition || gaPosition,
    business_line: businessLine,
    hide_brand_toggle: $getBrandCheckbox()?.checked || false,
    hide_material_toggle: $getMaterialCheckbox()?.checked || false,
    hide_price_toggle: $getPriceCheckbox()?.checked || false,
    hide_softness_score_toggle: $getCozyFeelCheckbox()?.checked || false,
    version: "v3"
  });
};

/* eslint-disable import/prefer-default-export */
