import { getWindowWidth } from "common/utils/browser";
import { getTopPosition } from "common/utils/ui/browser";
import "./masthead-menu-sticky.scss";

const MAX_WINDOW_WIDTH = 992; // desktop breakpoint
const HEADER_HEIGHT = 110;

const handleScroll = menu => {
  const method = getTopPosition() > HEADER_HEIGHT ? "add" : "remove";
  menu.classList[method]("masthead-menu-sticky--visible");
};

export default () => {
  const menu = document.querySelector(".masthead-menu-sticky");
  if (!menu) return;

  const hasStickyMenu = getWindowWidth() <= MAX_WINDOW_WIDTH;

  // only has the sticky menu for non desktop views
  if (hasStickyMenu) {
    handleScroll(menu); // initial one
    window.addEventListener("scroll", () => handleScroll(menu));
  }
};
