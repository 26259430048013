const $getCarouselWrappers = () => document.querySelectorAll(".js-mobile-grid-wrapper");
const $getCarousel = $wrapper => $wrapper.querySelector(".js-products-mobile-scroll-grid");
const $getArrows = $wrapper => ({
  $leftArrow: $wrapper.querySelector(".js-mobile-grid-arrow-left"),
  $rightArrow: $wrapper.querySelector(".js-mobile-grid-arrow-right")
});

const checkArrowsVisibility = $wrapper => {
  const { $leftArrow, $rightArrow } = $getArrows($wrapper);
  const $carousel = $getCarousel($wrapper);
  const isStartOfScroll = $carousel.scrollLeft === 0;
  const isEndOfScroll = $carousel.scrollLeft === $carousel.scrollWidth - $carousel.clientWidth;

  $leftArrow.classList.toggle("hidden", isStartOfScroll);
  $rightArrow.classList.toggle("hidden", isEndOfScroll);
};

const handlerCarouselScroll = ($wrapper, callback) => {
  $getCarousel($wrapper).addEventListener(
    "scroll",
    () => {
      checkArrowsVisibility($wrapper);
      callback();
    },
    { passive: true }
  );
};

const initProductsMobileCarousel = ({ scrollCallback }) => {
  $getCarouselWrappers().forEach($wrapper => {
    handlerCarouselScroll($wrapper, scrollCallback);
    checkArrowsVisibility($wrapper);
  });
};

export default initProductsMobileCarousel;
