import { on as onEvent } from "common/utils/ui/event";
import { trackEvent } from "./product-color-picker-tracker";
import { urlEnum } from "../../../../app/frontend/components/compositions/softness_score/component";

export default class ProductPrintCompositionsClass {
  constructor(
    link,
    options = {
      getTrackElements: () => ({})
    }
  ) {
    this.$link = document.querySelector(link);
    this.path = {};
    this.options = options;

    onEvent("click", link, () => this.trackEvent(), true);
  }

  trackEvent() {
    trackEvent("color_change_modal_print_clicked", this.options.getTrackElements());

    return this;
  }

  setPrintParamToLink(param, flag) {
    const [url] = this.$link.getAttribute("href").split("?");
    this.path[param] = flag;

    const additionalParams = Object.keys(this.path)
      .map(key => `${key}=${this.path[key]}`)
      .join("&");

    this.updateLinkPath(`${url}?${additionalParams}`);

    return this;
  }

  updateProductId(productId) {
    const { currentStore } = this.$link.dataset;
    const url = `${currentStore === "ca" ? "/ca" : ""}/products/${productId}/${
      urlEnum.print_compositions
    }`;
    this.updateLinkPath(url);

    return this;
  }

  updateLinkPath(url) {
    this.$link.setAttribute("href", url);

    return this;
  }
}
