import { EventBus, Events } from "jiffy-store";
import "./product-mosaic.scss";

const ROOT_SELECTOR = ".js-product-mosaic";

function handleMosaicClick(e) {
  e.stopPropagation();
  e.preventDefault();

  EventBus.publish(Events.TOGGLE_PRODUCT_COLOR_PICKER, e.currentTarget);
}

const keydownCallback = e => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    handleMosaicClick(e);
  }
};

export default () => {
  const elMosaics = document.querySelectorAll(ROOT_SELECTOR);

  elMosaics.forEach(mosaic => {
    mosaic.addEventListener("click", handleMosaicClick);
    mosaic.addEventListener("keydown", keydownCallback);
  });

  return () => {
    elMosaics.forEach(mosaic => {
      mosaic.removeEventListener("click", handleMosaicClick);
      mosaic.removeEventListener("keydown", keydownCallback);
    });
  };
};
