import { isMobile } from "common/utils/browser";
import { getTopPosition } from "common/utils/ui/browser";

// solution is taken from
// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
export const isElementVisible = $el => {
  const rect = $el.getBoundingClientRect();
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  const screenHeight = window.innerHeight || document.documentElement.clientHeight;

  // eslint-disable-next-line prettier/prettier
  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < screenWidth &&
    rect.top < screenHeight
  );
};

// more modern approach, with IntersectionObserver
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

const DEFAULT_OBSERVER_OPTIONS = {
  root: null, // visibility is relative to the browser viewport
  rootMargin: "0px",
  threshold: 1.0 // element must be fully visible
};

export const observeElementVisibility = (
  selector,
  callback,
  options = {
    mobileOnly: false,
    once: false,
    observerOptions: {}
  }
) => {
  let scrolled = false;
  let wasSeen = false;

  const $el = typeof selector === "string" ? document.querySelector(selector) : selector;

  const observerOptions = {
    ...DEFAULT_OBSERVER_OPTIONS,
    ...options.observerOptions
  };

  const observerCallback = entries => {
    if (options.mobileOnly && !isMobile()) {
      return; // we are checking for visibility in mobile viewports only
    }

    if (options.once && wasSeen) {
      return; // noop. The callback was already executed once
    }

    const intersectingEntries = entries.filter(entry => entry.isIntersecting);
    if (intersectingEntries.length > 0) {
      callback(intersectingEntries, scrolled);
      wasSeen = true;
    }
    scrolled = true;
  };

  const visibilityObserver = new IntersectionObserver(observerCallback, observerOptions);
  $el && visibilityObserver.observe($el);
  return visibilityObserver;
};

export const isScrollOnTop = () => getTopPosition() <= 10;

export default isElementVisible;
