/* eslint-disable import/prefer-default-export */
/**
 * Returns a random number in given range
 * @param min
 * @param max
 * @returns {number}
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min) + min);

/**
 * Generates a random string
 * @param length
 * @returns {string}
 */
export const generateRandomString = (length = 8) =>
  Math.random()
    .toString(36)
    .substring(2, 2 + length);
