import MixpanelEventClass from "common/utils/ui/mixpanel-event-class";
import "./digital-assets-asset-card.scss";

export default () =>
  new MixpanelEventClass({
    eventSelector: ".js-digital-assets-asset-card-link",
    mixpanelEventOptions: {
      eventName: "product_clicked"
    },
    attributesToContext: {
      brand: "brand",
      fulfillmentGroup: "fulfillment_group",
      businessLine: "business_line",
      context: "click_context",
      sku: "sku",
      product: "product",
      author: "author",
      pageName: "page_name",
      position: "item_position"
    }
  });
