/* eslint-disable max-lines-per-function */
import { track, sendDataLayerOnLinkClickEvent } from "jiffy-analytics";
import { readCookie } from "common/utils/cookie";
import initMenuSearch from "components/jiffy/masthead-search/masthead-search";
import initMobileMenu from "components/jiffy/mobile-menu/mobile-menu";
import initStickyMenu from "components/jiffy/masthead-menu-sticky/masthead-menu-sticky";
import { loadCartQuantity, initSavedCarts } from "components/jiffy/masthead-cart/masthead-cart";
import initMastheadAccount from "components/jiffy/masthead-account/masthead-account";
import initMastheadHercules from "components/jiffy/masthead-hercules/masthead-hercules";
import "components/jiffy/jiffy-first-badge/jiffy-first-badge";
import "components/jiffy/masthead-favorite/masthead-favorite";

import initMastheadMenu from "components/jiffy/masthead-menu/masthead-menu";
import "./masthead.scss";

// These are critical styles that are needed for the masthead to render correctly
// Other delivery-to styles will be loaded asynchronously
import "components/jiffy/deliver-to/deliver-to-toggle-desktop/deliver-to-toggle-desktop.scss";
import "components/jiffy/deliver-to/deliver-to-toggle-mobile/deliver-to-toggle-mobile.scss";

const initGAEvents = () => {
  const links = Array.from(document.querySelectorAll(".masthead a"));
  links.forEach(link => {
    link.addEventListener("click", e => {
      const { gaEventLabel } = link.dataset;
      if (gaEventLabel) {
        sendDataLayerOnLinkClickEvent(e, {
          genericEvent: true,
          eventCategory: "topnav",
          event: "click",
          eventLabel: gaEventLabel
        });
      }
    });
  });
};

const fillNameFromCookies = () => {
  const showMastheadRedesign = Boolean(document.querySelector(".masthead.ab_desktop_header_nav"));

  const $nameWrapper = document.getElementById("js-masthead-account-name");
  const name = readCookie("name");

  let linkText;
  let userType;
  if ($nameWrapper) {
    if (name) {
      linkText = showMastheadRedesign
        ? decodeURIComponent(name)
        : `Hello ${decodeURIComponent(name)}`;
      userType = "signed_in";
      $nameWrapper.classList.add("masthead-account__label--name");
    } else {
      linkText = "Sign In";
      userType = "guest_user";
    }
    $nameWrapper.textContent = linkText;
    $nameWrapper.addEventListener("click", () => {
      track("user_account_clicked", {
        text: linkText,
        user_type: userType
      });
    });
  }
};

const init = () => {
  fillNameFromCookies();
  initMastheadMenu();
  initMenuSearch();
  initMobileMenu();
  initStickyMenu();
  loadCartQuantity();
  initSavedCarts();
  initGAEvents();
  initMastheadAccount();
  initMastheadHercules();
};

document.addEventListener("DOMContentLoaded", init);
