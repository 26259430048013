import { track } from "jiffy-analytics";
import { on as onEvent } from "../../../common/utils/ui/event";
import "./site-jabber.scss";

const pageUrl = window.location.pathname;

const trackReviewRatingEvent = () => {
  track("sitejabber_review_clicked", {
    url: pageUrl,
    action: "review_rating_clicked"
  });
};

const trackReviewStarsEvent = () => {
  track("sitejabber_review_clicked", {
    url: pageUrl,
    action: "review_stars_clicked"
  });
};

const trackReviewVerifyEvent = () => {
  track("sitejabber_review_clicked", {
    url: pageUrl,
    action: "review_verified_clicked"
  });
};

// NOTE: Don't have full control of the selector classes as it is automatically injected by sitejabber
onEvent("click", ".stjr-review-badge-box__rating__link", trackReviewRatingEvent, true);
onEvent("click", ".stjr-review-badge-box__rating-stars a", trackReviewStarsEvent, true);
onEvent("click", ".stjr-reviews-carousel-attribution__link", trackReviewVerifyEvent, true);
