import { track } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";

export default class PrintOptionsAnimationClass {
  constructor(wrapperSelector, toggleSelector, animationClass) {
    this.isOpen = false;
    this.$wrapper = document.querySelector(wrapperSelector);
    this.animationClass = animationClass;

    onEvent("click", toggleSelector, this.onChange.bind(this), true);
  }

  onChange() {
    this.isOpen = !this.isOpen;
    this.$wrapper?.classList.toggle(this.animationClass, this.isOpen);
    this.#trackEvent();

    return this;
  }

  // private
  #trackEvent() {
    track("print_options_toggle", {
      action: this.isOpen ? "open" : "close"
    });
  }
}
