import { track } from "jiffy-analytics";
import { EventBus, Events } from "jiffy-store";

const $herculesButton = () => document.querySelector(".js-mobile-menu-hercules");

export default () => {
  $herculesButton()?.addEventListener("click", () => {
    EventBus.publish(Events.SHOW_HERCULES_CHAT);
    track("herculesgpt.accessed_via_mobile_menu");
  });
};
