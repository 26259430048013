import { on as onEvent } from "common/utils/ui/event";

export default class EventClass {
  #eventCallback;

  constructor(
    props = {
      eventSelector: "",
      event: "",
      eventCallback: undefined,
      eventOptions: {}
    }
  ) {
    this.#eventCallback = props.eventCallback;

    onEvent(props.event || "click", props.eventSelector, this.event.bind(this), {
      capture: true,
      ...props.eventOptions
    });
  }

  /**
   * @method event
   * @description This method is called when the event is triggered.
   * @param {Event} event
   * @param {HTMLElement} target
   * @returns {void}
   */
  event(event, target) {
    this.#eventCallback?.(event, target);
  }
}
