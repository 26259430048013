import initNavigaiton from "components/masthead/helpers/init_navigation";

import "./component.scss";

export default initNavigaiton({
  navigationLinksSelector: ".js-masthead-stickers-navigation-link",
  navigationContentSelector: ".js-masthead-stickers-navigation-content",
  detailsContentSelector: ".js-masthead-stickers-navigation-details-container",
  activeLinkClassName: "masthead-stickers-navigation__section--active"
});
