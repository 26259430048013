export const defaultModalArguments = (className = "modal--default") => ({
  onBeforeOpen: () => {
    document.body.classList.add(className);
  },
  onBeforeClose: () => {
    document.body.classList.remove(className);
  }
});

export const recaptchaModalArguments = () => ({
  onBeforeOpen: () => {
    document.body.classList.add("modal--recaptcha");
  },
  onBeforeClose: () => {
    document.body.classList.remove("modal--recaptcha");
  }
});

export const hideCurrentModal = () => {
  const currentModal = document.querySelector(".modal.in");
  if (!currentModal) return;

  $(currentModal).modal("hide");
};
