/* eslint-disable class-methods-use-this */
import EventClass from "common/utils/ui/event-class";
import { track } from "jiffy-analytics";

class DTFCategoryFilter extends EventClass {
  #$easyShoppingOriginalSibling;

  get #$sections() {
    return document.querySelectorAll(".js-dtf-category");
  }

  get #$sectionFillers() {
    return document.querySelectorAll(".js-dtf-category-filler");
  }

  get #$easyShopping() {
    return document.querySelector(".js-dtf-category-easy-shopping");
  }

  constructor() {
    super({
      eventSelector: ".js-dtf-category-header"
    });
  }

  /* eslint-disable max-lines-per-function */
  event(e, $header) {
    const { headerSection } = e.target.dataset;
    if ($header && headerSection) {
      track("dtf_category_selected", { category: headerSection });
      const isAllProducts = headerSection === "All Products";
      const $headingItems = Array.from($header.children);
      // Match the index of the heading item with the index of the section
      const $sections = [null, ...Array.from(this.#$sections)];
      const $sectionFillers = Array.from(this.#$sectionFillers);
      const $easyShopping = this.#$easyShopping;
      const $sectionContainer = this.#$sections[0].parentElement;

      $headingItems.forEach(($heading, index) => {
        $heading.classList.toggle("active", $heading.dataset.headerSection === headerSection);

        if (index > 0) {
          const $sectionHeader = $sections[index]?.querySelector(".js-products-header");

          $sectionHeader.classList.toggle("hidden", !isAllProducts);
          $sections[index].classList.toggle(
            "hidden",
            !(isAllProducts || $sections[index].dataset.section === headerSection)
          );
        }
      });

      $sectionFillers.forEach($filler => {
        $filler.classList.toggle("hidden", !isAllProducts);
      });

      // may not exist if sections is less than 3
      if ($easyShopping) {
        // set original sibling once
        if (!this.#$easyShoppingOriginalSibling) {
          this.#$easyShoppingOriginalSibling = $easyShopping.nextElementSibling;
        }

        if (isAllProducts) {
          // move back
          $sectionContainer.insertBefore($easyShopping, this.#$easyShoppingOriginalSibling);
        } else {
          // move last
          $sectionContainer.appendChild($easyShopping);
        }
      }
    }
  }
}

export default () => new DTFCategoryFilter();
