/* eslint-disable max-lines-per-function, max-params */
import { isMobile } from "common/utils/browser";

let activeBadge = null;
const SHOWING_DELAY = 600;

/**
 *
 * @param parentPosition
 * @param badgePosition
 * @param popupDirection[String]
 * @param customOffsets
 * @returns {{popupPositionLeft: number, popupPositionTop: number, tailPositionTop: number, tailPositionLeft: number}}
 */
const getPopupPosition = (badgePosition, customOffsets, popupDirection = "vertical") => {
  const POPUP_WIDTH = 310;
  const HALF_SIZE_TAIL = 11;
  const parentPosition = document.body.getBoundingClientRect();

  const getPopupPositionVertical = () => {
    const popupPositionTop = badgePosition.top - parentPosition.top - customOffsets.top;
    let popupPositionLeft =
      badgePosition.left - parentPosition.left + badgePosition.width / 2 - POPUP_WIDTH / 2;
    const tailPositionTop = popupPositionTop + customOffsets.tail_top;
    let tailPositionLeft =
      badgePosition.left - parentPosition.left + badgePosition.width / 2 - HALF_SIZE_TAIL;

    if (popupPositionLeft < 0) {
      popupPositionLeft = 0;
    } else if (parentPosition.width < popupPositionLeft + POPUP_WIDTH) {
      popupPositionLeft = parentPosition.width - POPUP_WIDTH;

      if (tailPositionLeft + HALF_SIZE_TAIL * 2 > popupPositionLeft + POPUP_WIDTH) {
        tailPositionLeft = popupPositionLeft + POPUP_WIDTH - HALF_SIZE_TAIL * 2 - 9; // 9 - border-radius on popup
      }
    }

    return { popupPositionTop, popupPositionLeft, tailPositionTop, tailPositionLeft };
  };

  const getPopupPositionHorizontal = () => {
    const LEFT_OFFSET = 17;
    const popupPositionTop = badgePosition.top - parentPosition.top + badgePosition.height / 2;
    let popupPositionLeft =
      badgePosition.left - parentPosition.left + badgePosition.width + LEFT_OFFSET;
    const tailPositionTop = popupPositionTop - HALF_SIZE_TAIL;
    let tailPositionLeft = badgePosition.left - parentPosition.left + badgePosition.width + 10;

    if (parentPosition.width < popupPositionLeft + POPUP_WIDTH) {
      popupPositionLeft = badgePosition.left - parentPosition.left - POPUP_WIDTH - LEFT_OFFSET;
      tailPositionLeft = badgePosition.left - parentPosition.left - 32;
    }

    return { popupPositionTop, popupPositionLeft, tailPositionTop, tailPositionLeft };
  };

  return popupDirection === "horizontal"
    ? getPopupPositionHorizontal()
    : getPopupPositionVertical();
};

const handleOpen = params => {
  const { badge, customOffsets, delay, popup, popupTail, sendGAEvent } = params;
  if (popup.classList.contains("open")) return;

  clearTimeout(window.badgesTimeout);

  window.badgesTimeout = setTimeout(() => {
    activeBadge = badge;
    if (sendGAEvent) sendGAEvent(badge.dataset);

    const badgePosition = badge.getBoundingClientRect();
    const { popupPosition } = badge.dataset;
    const popupDirection = !(isMobile() && popupPosition === "horizontal")
      ? popupPosition
      : undefined;

    const { popupPositionTop, popupPositionLeft, tailPositionTop, tailPositionLeft } =
      getPopupPosition(badgePosition, customOffsets, popupDirection);

    ["bottom", "horizontal", "vertical"].forEach(className => {
      popup.classList.toggle(className, popupDirection === className);
    });
    popup.classList.add("open");
    popup.setAttribute("style", `top: ${popupPositionTop}px; left: ${popupPositionLeft}px`);
    popupTail.setAttribute("style", `top: ${tailPositionTop}px; left: ${tailPositionLeft}px`);
  }, delay || 0);
};

const handleClose = (popup, immediate = true) => {
  if (immediate) {
    popup.classList.remove("open");
  } else {
    clearTimeout(window.badgesTimeout);
    window.badgesTimeout = setTimeout(() => {
      popup.classList.remove("open");
    }, SHOWING_DELAY);
  }
};

const handleKeydown = e => {
  if (e.keyCode !== 9) return;

  e.preventDefault();
  setTimeout(() => {
    document.querySelector("#j1-popup-link")?.focus();
  }, 0);
};

const preventClose = () => {
  clearTimeout(window.badgesTimeout);
};

export const initBadges = (SELECTORS, customOffsets, sendGAEvent) => {
  const popup = document.querySelector(SELECTORS.POPUP);
  if (!popup) return;
  const badges = document.querySelectorAll(`${SELECTORS.BADGE}:not(.initialized)`);
  const popupTail = document.querySelector(SELECTORS.POPUP_TAIL);

  const handleOpenParams = {
    customOffsets,
    popup,
    popupTail,
    sendGAEvent
  };

  badges.forEach(badge => {
    const { hideOnScroll, toggleClick } = badge.dataset;
    badge.classList.add("initialized");

    if (toggleClick === "true") {
      badge.addEventListener("click", e => {
        if (popup.classList.contains("open")) {
          handleClose(popup);
        } else {
          handleOpen({ ...handleOpenParams, badge }, e);
        }
      });
    } else {
      badge.addEventListener("keydown", handleKeydown);
      badge.addEventListener("focus", handleOpen.bind(null, { ...handleOpenParams, badge }));
      badge.addEventListener(
        "mouseenter",
        handleOpen.bind(null, { ...handleOpenParams, badge, delay: SHOWING_DELAY })
      );
      badge.addEventListener("touchstart", handleOpen.bind(null, { ...handleOpenParams, badge }), {
        passive: true
      });
    }

    badge.addEventListener("mouseleave", handleClose.bind(null, popup, false));
    if (hideOnScroll === "true") {
      window.addEventListener("scroll", handleClose.bind(null, popup), true);
    }
  });
};

/**
 * Factory for sitewide badges like jiffy-first badges
 * @param SELECTORS
 * @param SELECTORS.WRAPPER
 * @param SELECTORS.BADGE
 * @param SELECTORS.POPUP
 * @param SELECTORS.POPUP_TAIL
 * @param SELECTORS.POPUP_CLOSE
 * @param customOffsets - custom offset for elements(because popup heights are different)
 * @param customOffsets.top
 * @param customOffsets.tail_top
 * @param sendGAEvent
 * @constructor
 */
export default function PopupFactory(SELECTORS, customOffsets, sendGAEvent) {
  const popup = document.querySelector(SELECTORS.POPUP);
  if (!popup) return;

  // BADGES LISTENERS
  initBadges(SELECTORS, customOffsets, sendGAEvent);

  // POPUP LISTENERS
  if (popup.getAttribute("listener") !== "true") {
    const closeBtn = popup.querySelector(SELECTORS.POPUP_CLOSE);
    closeBtn?.addEventListener("click", handleClose.bind(null, popup));

    popup.addEventListener("mouseleave", handleClose.bind(null, popup));
    popup.addEventListener("mouseenter", preventClose);
    popup.addEventListener("mousemove", preventClose);
    popup.setAttribute("listener", "true");
  }

  // DOCUMENT LISTENERS
  // close tooltip when click outside the trigger
  document.addEventListener("touchstart", e => {
    if (
      e.target.closest(SELECTORS.BADGE) ||
      e.target.closest(".j1-badge-popup") ||
      e.target.classList.contains(SELECTORS.BADGE.slice(1)) ||
      e.target.classList.contains("j1-badge-popup")
    ) {
      return;
    }

    handleClose(popup);
  });

  // close tooltip due tab navigation
  const popupLink = popup.querySelector(SELECTORS.POPUP_LINK);
  popupLink?.addEventListener("keydown", e => {
    if (e.keyCode !== 9) return;

    activeBadge?.focus();
    handleClose(popup);
  });
}
