import { track } from "jiffy-analytics";
import EventClass from "./event-class";

export default class MixpanelEventClass extends EventClass {
  #mixpanelEventOptions = {};

  #attributesToContext = {};

  constructor(props) {
    if (new.target !== MixpanelEventClass) {
      throw new TypeError("Cannot construct MixpanelEventClass subclasses directly");
    }

    super(props);
    this.#mixpanelEventOptions = props.mixpanelEventOptions || {};
    this.#attributesToContext = props.attributesToContext || {};
  }

  addOrModifyMixpanelPayload(option) {
    this.#mixpanelEventOptions.eventPayload = {
      ...this.#mixpanelEventOptions.eventPayload,
      ...option
    };
  }

  removeMixpanelPayloadOption(option) {
    delete this.#mixpanelEventOptions.eventPayload[option];
  }

  #prepareContextPayload(dataset) {
    return Object.keys(dataset).reduce((acc, key) => {
      if (Object.hasOwn(this.#attributesToContext, key)) {
        acc[this.#attributesToContext[key]] = dataset[key];
      }

      return acc;
    }, {});
  }

  event(event, target) {
    super.event(event, target);

    this.#sendEvent(this.#prepareContextPayload(target.dataset));
  }

  #sendEvent(contextPayload) {
    const { eventName, eventPayload } = this.#mixpanelEventOptions;
    track(eventName, {
      ...eventPayload,
      ...contextPayload
    });
  }
}
