import { isCrawler } from "common/utils/browser";

const showUrlsHiddenFromCrawlers = () => {
  if (isCrawler()) {
    return;
  }

  document.querySelectorAll(".js-url-hidden-from-crawlers").forEach(el => {
    if (el.dataset.originalUrl !== undefined) {
      el.setAttribute("href", el.dataset.originalUrl);
    }
  });
};

const init = () => {
  showUrlsHiddenFromCrawlers();
};

export default init;
