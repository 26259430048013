import { getRandomInt } from "common/utils/helpers/random";
import "./product-details-dtf-progress.scss";

const $getContainer = $root => $root.querySelector(".js-dtf-progress");
const $getFilename = $container => $container.querySelector(".js-dtf-progress-file-name");
const $getProgressValue = $container => $container.querySelector(".js-dtf-progress-value");
const $getProgressLine = $container => $container.querySelector(".js-dtf-progress-line");

const UPDATE_INTERVAL = 400;

class ProductDetailsDtfProgress {
  constructor($root = document, callback = null) {
    this.$container = $getContainer($root);
    if (!this.$container) return;
    this.$filename = $getFilename(this.$container);
    this.$progressValue = $getProgressValue(this.$container);
    this.$progressLine = $getProgressLine(this.$container);

    this.previousTimeStamp = 0;
    this.percentage = 0;

    this.setFilename = this.setFilename.bind(this);
    this.start = this.start.bind(this);
    this.update = this.update.bind(this);
    this.stop = this.stop.bind(this);

    this.callback = callback;
  }

  setFilename(filename) {
    this.$filename.textContent = filename;
  }

  start() {
    this.$container.classList.remove("hidden");
  }

  setValues(value) {
    this.percentage = Math.floor(value);
    this.$progressValue.textContent = this.percentage;
    this.$progressLine.style.width = `${this.percentage}%`;

    if (this.callback) {
      this.callback(this.percentage);
    }
  }

  update(timestamp) {
    if (timestamp - this.previousTimeStamp > getRandomInt(UPDATE_INTERVAL, UPDATE_INTERVAL + 100)) {
      this.previousTimeStamp = timestamp;
      this.setValues(this.percentage + getRandomInt(3, 7));
    }
    if (this.percentage >= 93) return;
    this.cancelToken = requestAnimationFrame(this.update);
  }

  hide() {
    this.$container.classList.add("hidden");
  }

  stop() {
    this.setValues(0);
    if (this.cancelToken) {
      cancelAnimationFrame(this.cancelToken);
      this.cancelToken = null;
    }
  }
}

export default ProductDetailsDtfProgress;
