/* eslint-disable max-lines-per-function */
import { checkOnePageCookie } from "common/utils/cookie";
import "./masthead-account-popup.scss";

const $getEntryModal = () => document.getElementById("js-guest-entry-modal");
const $getPopup = () => document.querySelector(".js-masthead-account-popup");

const setVisibilityPopup = isOpen => {
  const $popup = $getPopup();

  $popup.classList.toggle("masthead-account-popup--is-open", isOpen);
};

const showPopup = signedIn => {
  if (signedIn) return;

  setVisibilityPopup(true);

  const hidePopupTimeout = setTimeout(() => {
    setVisibilityPopup(false);
  }, 6000);

  document.addEventListener("click", event => {
    const clickInside =
      event.composedPath().includes($getPopup()) || event.composedPath().includes($getEntryModal());
    if (!clickInside) {
      clearTimeout(hidePopupTimeout);
      setVisibilityPopup(false);
    }
  });
};

const waitForDynamicData = () => {
  if (window.dynamicDataLoaded) {
    showPopup(document.pdpDynamicData.signed_in);
  } else {
    document.addEventListener(
      "dynamicDataLoaded",
      () => {
        showPopup(document.pdpDynamicData.signed_in);
      },
      false
    );
  }
};

const initMastheadAccountPopup = () => {
  const { signedIn } = document.body.dataset;
  const $popup = $getPopup();
  if (!$popup || checkOnePageCookie("masthead-account-popup")) return;

  if (signedIn) {
    showPopup(signedIn === "true");
  } else {
    waitForDynamicData();
  }
};

export default initMastheadAccountPopup;
