/* eslint-disable class-methods-use-this, no-new */
import EventClass from "common/utils/ui/event-class";
import "./digital-assets-images-preview.scss";

export class DigitalAssetsImagesPreview {
  #wrapperSelector;

  #imgSelector = "img[data-src]:not(.lazyloaded)";

  #lazyloadClass = "lazyload";

  #resetAnimationClass = "reset-animation";

  constructor({ wrapperSelector }) {
    this.#wrapperSelector = wrapperSelector;
  }

  #addImageProcess(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    });
  }

  #eventOver(_, $wrapper) {
    const loadImages = [];

    $wrapper.classList.remove(this.#resetAnimationClass);

    $wrapper.querySelectorAll(this.#imgSelector).forEach($img => {
      $img.classList.add(this.#lazyloadClass);
      loadImages.push(this.#addImageProcess($img.dataset.src));
    });

    if (loadImages.length > 0) {
      Promise.all(loadImages).then(() => {
        $wrapper.classList.add("slide-show");
      });
    }
  }

  #eventOut(_, $wrapper) {
    setTimeout(() => {
      $wrapper.classList.add(this.#resetAnimationClass);
    }, 500); // 500ms is the duration of the transition of cover image
  }

  init() {
    new EventClass({
      eventSelector: this.#wrapperSelector,
      event: "mouseover",
      eventCallback: this.#eventOver.bind(this),
      eventOptions: { passive: true }
    });
    new EventClass({
      eventSelector: this.#wrapperSelector,
      event: "mouseout",
      eventCallback: this.#eventOut.bind(this),
      eventOptions: { passive: true }
    });
  }
}

export default () =>
  new DigitalAssetsImagesPreview({
    wrapperSelector: ".js-digital-assets-images-preview"
  });
