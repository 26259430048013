/* eslint-disable max-lines-per-function, max-lines */
import { api } from "jiffy-api";
import mobileApp from "common/utils/mobile/app";
import debounce from "lodash.debounce";
import { initDropdownNavigation } from "common/jiffy/accessibility";
import { redirectTo } from "common/utils/browser";
import { track } from "jiffy-analytics";
import { EventBus, Events } from "jiffy-store";
import "./masthead-cart.scss";

const MODIFIERS = {
  OPEN: "masthead-cart__saved-carts-wrapper--open"
};

const SELECTORS = {
  COUNTER:
    ".js-masthead-cart-counter, .masthead-menu-sticky__counter, .project-bar__counter, .js-masthead-mobile-cart-counter",
  CARTS_LABEL: ".js-masthead-carts-label",
  CARTS_LABEL_INNER: ".js-masthead-carts-label-inner",
  SAVED_CARTS_WRAPPER: ".js-masthead-saved-carts-wrapper",
  SAVED_CARTS_LIST: ".js-masthead-saved-carts-list",
  SAVED_CARTS_LINK: ".js-masthead-saved-carts-link",
  SAVED_CARTS_ITEM: ".js-masthead-saved-carts-item",
  SAVED_CARTS_OPTION: ".js-masthead-saved-cart-option",
  SAVED_CARTS_SPINNER: ".js-masthead-saved-cart-spinner",
  SAVED_CARTS_SPINNER_TEXT: ".js-masthead-saved-cart-spinner-switching",
  MASTHEAD_CART: ".js-masthead-cart",
  MASTHEAD_CART_LINK: ".js-masthead-cart-link",
  BADGE: ".js-masthead-saved-cart-badge"
};

const $getMastheadCart = () => document.querySelector(SELECTORS.MASTHEAD_CART);

export const updateHeaderCounter = quantity => {
  const cartCounters = document.querySelectorAll(SELECTORS.COUNTER);
  cartCounters.forEach(counter => {
    const cartCounter = counter;
    cartCounter.innerHTML = quantity;
    cartCounter.classList.remove("hidden");
  });
  mobileApp.mq.publish("quantity", quantity);
};

export async function loadCartQuantity() {
  try {
    const { quantity } = await api.jiffyshirts.cart.quantity();
    updateHeaderCounter(quantity);
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export const incrementHeaderCounter = quantity => {
  const cartCounters = document.querySelectorAll(SELECTORS.COUNTER);
  if (cartCounters.length) {
    let newQuantity = 0;
    cartCounters.forEach(counter => {
      const cartCounter = counter;
      newQuantity = parseInt(cartCounter.innerHTML, 10) + quantity;
      cartCounter.innerHTML = newQuantity;
      cartCounter.classList.remove("hidden");
    });
    mobileApp.mq.publish("quantity", newQuantity);
  } else {
    loadCartQuantity();
  }
};

const trackSavedCartsEvent = eventName => {
  track(eventName, { trigger: "view_cart_header" });
};

const initSavedCartsLink = () => {
  const savedCartsLink = document.querySelector(SELECTORS.SAVED_CARTS_LINK);
  if (!savedCartsLink) return;
  const { redirectUrl } = savedCartsLink.dataset;

  savedCartsLink.addEventListener("click", e => {
    e.preventDefault();
    e.stopPropagation();

    trackSavedCartsEvent("saved_carts_manage_link_clicked");
    redirectTo(redirectUrl);
  });
};

const initSavedCartItems = (cart, cartLink) => {
  const savedCartsOptions = document.querySelectorAll(SELECTORS.SAVED_CARTS_OPTION);
  const spinner = document.querySelector(SELECTORS.SAVED_CARTS_SPINNER);
  const spinnerText = spinner.querySelector(SELECTORS.SAVED_CARTS_SPINNER_TEXT);

  const { number, active } = cart.dataset;
  cart.addEventListener("click", async e => {
    e.preventDefault();
    e.stopPropagation();

    spinnerText.classList.remove("hidden");
    spinner.classList.remove("hidden");
    if (active === "true") {
      redirectTo(cartLink.href);
      return;
    }

    savedCartsOptions.forEach(option =>
      option.classList.toggle(
        "masthead-cart__saved-carts-option--active",
        option.dataset.number === number
      )
    );

    try {
      await api.account.savedCarts.switch({ number });
      await loadCartQuantity();
    } catch (error) {
      spinner.classList.add("hidden");
      spinnerText.classList.add("hidden");
    }

    trackSavedCartsEvent("saved_carts_default_changed");
    redirectTo(cartLink.href);
  });
};

const trackDropdownOpen = () => {
  const $wrapper = document.querySelector(SELECTORS.SAVED_CARTS_WRAPPER);
  const $label = document.querySelector(SELECTORS.CARTS_LABEL);

  $wrapper?.addEventListener(
    "mouseenter",
    debounce(() => {
      trackSavedCartsEvent("saved_carts_dropdown_opened");
    }, 300)
  );

  $label?.addEventListener(
    "touchstart",
    debounce(() => {
      trackSavedCartsEvent("saved_carts_dropdown_opened");
    }, 300)
  );
};

export const changeActiveCartName = newName => {
  const activeItemName = document.querySelector(
    '.js-masthead-saved-carts-item[data-active="true"] .js-masthead-cart-name'
  );
  if (!activeItemName) return;

  activeItemName.innerHTML = newName;
};

export const changeCartName = (cartNumber, newName) => {
  const activeItemName = document.querySelector(
    `.js-masthead-saved-carts-item[data-number="${cartNumber}"] .js-masthead-cart-name`
  );
  if (!activeItemName) return;

  activeItemName.innerHTML = newName;
};

const renderItem = (cart, active) => {
  const element = document.createElement("template");
  const cartLink = document.querySelector(SELECTORS.MASTHEAD_CART_LINK);
  const template = `
    <span
      class="masthead-cart__saved-carts-item js-masthead-saved-carts-item"
      data-number="${cart.number}" data-active="${active}"
    >
      <div
        class="masthead-cart__saved-carts-option js-masthead-saved-cart-option ${
          active ? "masthead-cart__saved-carts-option--active" : ""
        }"
        data-number="${cart.number}"
      ></div>
      <div class="masthead-cart__cart-name js-masthead-cart-name">
        ${cart.cart_name}
      </div>
      <div
        class="masthead-cart__badge js-masthead-saved-cart-badge ${active ? "" : "hidden"}" 
        data-number="${cart.number}"
      >
        Active Cart
      </div>
    </span>
  `;

  element.innerHTML = template.trim();
  const node = element.content.firstChild;
  initSavedCartItems(node, cartLink);
  return node;
};

const renderSavedCarts = (carts, activeCart) => {
  const list = document.querySelector(SELECTORS.SAVED_CARTS_LIST);
  if (!list) return;
  const spinner = document.querySelector(SELECTORS.SAVED_CARTS_SPINNER);

  const items = carts.map(cart =>
    renderItem(cart, activeCart && activeCart.number === cart.number)
  );
  spinner.classList.add("hidden");
  list.replaceChildren(...items);
};

const initListeners = () => {
  EventBus.on(Events.SAVED_CART_SELECTED, (_, { number, items }) => {
    const savedCartsOptions = document.querySelectorAll(SELECTORS.SAVED_CARTS_OPTION);
    const savedCartsBadges = document.querySelectorAll(SELECTORS.BADGE);
    savedCartsOptions.forEach(option =>
      option.classList.toggle(
        "masthead-cart__saved-carts-option--active",
        option.dataset.number === number
      )
    );
    savedCartsBadges.forEach(option =>
      option.classList.toggle("hidden", option.dataset.number !== number)
    );
    updateHeaderCounter(items);
  });

  EventBus.on(Events.SAVED_CART_CREATED, async (_, newCart) => {
    const list = document.querySelector(SELECTORS.SAVED_CARTS_LIST);
    const savedCartsOptions = document.querySelectorAll(SELECTORS.SAVED_CARTS_OPTION);
    const savedCartsBadges = document.querySelectorAll(SELECTORS.BADGE);
    savedCartsOptions.forEach(option =>
      option.classList.remove("masthead-cart__saved-carts-option--active")
    );
    savedCartsBadges.forEach(option => option.classList.add("hidden"));
    const item = renderItem(newCart, true);
    list.prepend(item);
  });
};

export async function initSavedCarts() {
  const $mastheadCart = $getMastheadCart();
  if ($mastheadCart?.dataset?.savedCarts === "false") return;

  const response = await Promise.all([
    api.jiffyshirts.savedCart.list(),
    api.jiffyshirts.savedCart.current()
  ]);
  if (response[0].error) return;

  const [{ carts }, active] = response;

  renderSavedCarts(carts, active);
  initListeners();
  trackDropdownOpen();
  initSavedCartsLink();
  initDropdownNavigation({
    openClass: MODIFIERS.OPEN,
    triggerClass: SELECTORS.CARTS_LABEL_INNER
  });
}
