export default class AppRegistryModules {
  modules = new Map();

  subscribe = null;

  constructor(subscribe) {
    this.subscribe = subscribe;
  }

  get state() {
    return [...this.modules.keys()].reduce(
      (acct, module) => {
        const moduleStates = acct.modules[module] || [];
        const currentModule = this.modules.get(module);
        const currentState = currentModule.state;
        return {
          ...acct,
          state: {
            ...acct.state,
            [currentState]: [...(acct.state[currentState] || []), module]
          },
          modules: {
            ...acct.modules,
            [module]: [...moduleStates, currentModule]
          }
        };
      },
      { modules: {}, state: {} }
    );
  }

  change(moduleName, state) {
    const at = { [`${state}`]: +new Date() };
    const module = this.modules.get(moduleName) || { name: moduleName, state, at };
    this.modules.set(moduleName, { ...module, state, at: { ...module.at, ...at } });
    this.notify(moduleName);
  }

  notify(moduleName) {
    if (this.subscribe) {
      const module = this.modules.get(moduleName);
      this.subscribe({ event: "change", data: { module, state: this.state } });
    }
  }
}
