import MobileMenuClassOld from "./mobile-menu-class";

export default class MobileMenuClass extends MobileMenuClassOld {
  constructor(
    props = {
      closeMenuSelector: "",
      contentWrapperSelector: "",
      bannerWrapperSelector: "",
      menuWrapperSelector: "",
      menuCaptionsSelector: "",
      submenuTriggerButtonSelector: "",
      buttonActiveClass: "",
      documentLockedClass: "",
      submenuActiveClass: "",
      openMenuClass: ""
    }
  ) {
    super(props);
    this.$menuCaptions = document.querySelectorAll(props.menuCaptionsSelector);
  }

  onMenuTriggerClick({ target: $target }) {
    const $button =
      $target.tagName === "BUTTON"
        ? $target
        : $target.closest(this.props.submenuTriggerButtonSelector);
    const { direction, category } = $button.dataset;

    let level;

    if (direction === "back") {
      const $submenus = this.$getAllActiveTriggerButtons();

      const $lastSubmenu = $submenus[$submenus.length - 1];
      $lastSubmenu.classList.remove(this.props.buttonActiveClass);
      level = String(Number($lastSubmenu.dataset.level) + 1);

      if (level === "1") {
        this.$menuWrapper.classList.remove(this.props.submenuActiveClass);
        this.showCategoryHeader("root");
      }

      const prevLevel = String(level - 1);
      this.$menuWrapper.scroll(0, this.scrollPosition[prevLevel]);
    } else {
      level = $button.dataset.level;
      $button.classList.add(this.props.buttonActiveClass);
      this.scrollPosition[level] = this.$menuWrapper.scrollTop;
      this.$menuWrapper.scroll(0, 0);

      if (level === "0") {
        this.showCategoryHeader(category);
        this.$menuWrapper.classList.add(this.props.submenuActiveClass);
      }
    }

    const currentTransition = -100 * level;
    const shift = direction === "forward" ? -100 : 100;
    this.$contentWrapper.style.transform = `translate3d(${currentTransition + shift}%, 0, 0)`;
  }

  updateBannerWithHtml({ html }) {
    if (!this.$bannerWrapper) {
      return this;
    }

    this.$bannerWrapper.innerHTML = html;

    return this;
  }

  showCategoryHeader(name) {
    this.$menuCaptions.forEach($caption => {
      if ($caption.dataset.menuTreeHeadline === name) {
        $caption.classList.remove("hidden");
      } else {
        $caption.classList.add("hidden");
      }
    });
  }
}
