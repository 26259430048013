/* eslint-disable max-lines-per-function */
import { isTouchDevice } from "common/utils/browser";
import { on as onEvent } from "common/utils/ui/event";

export const initDropdownNavigation = ({ openClass, triggerClass }) => {
  const $triggers = document.querySelectorAll(triggerClass);

  $triggers.forEach($item => {
    const $trigger = $item;
    const $content = $trigger.nextElementSibling;
    if (!$content) return;

    const { slice } = Array.prototype;
    const contentClass = `.${slice.call($content.classList).join(".")}`;

    $trigger.addEventListener("keydown", e => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();

        const isOpened = $content.classList.contains(openClass);
        $content.classList.toggle(openClass, !isOpened);
        $trigger.ariaExpanded = !isOpened;
      }

      if (e.keyCode === 27) {
        $content.classList.remove(openClass);
        $trigger.ariaExpanded = "false";
      }
    });

    $trigger.addEventListener("click", e => {
      if (!isTouchDevice()) return;

      e.preventDefault();
      e.stopPropagation();
    });

    $content.addEventListener("focusout", () => {
      // allow browser to change active element
      setTimeout(() => {
        if (document.activeElement === document.body) return;

        const $closest = document.activeElement.closest(contentClass);
        if (!$closest) {
          $content.classList.remove(openClass);
          $trigger.ariaExpanded = "false";
        }
      }, 0);
    });

    $content.addEventListener("keydown", e => {
      if (e.keyCode === 27) {
        $content.classList.remove(openClass);
        $trigger.ariaExpanded = "false";
        $trigger.focus();
      }
    });
  });
};

const initRadioButtonsControl = () => {
  const callback = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.target.click();
      e.preventDefault();
    }
  };

  onEvent("keydown", ".a11y-label", callback, true);
};

export const applyModalNavigation = $modal => {
  const callback = e => {
    if (e.code !== "Tab") return;

    const focusableElements =
      "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";
    const $focusableContent = $modal.querySelectorAll(focusableElements);
    const focusableArray = Array.prototype.slice.call($focusableContent);
    const visibleElements = focusableArray.filter($node => !!$node.offsetParent);
    const $firstFocusableElement = visibleElements[0];
    const $lastFocusableElement = visibleElements[visibleElements.length - 1];

    if (e.shiftKey) {
      if (document.activeElement === $firstFocusableElement) {
        $lastFocusableElement.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === $lastFocusableElement) {
      $firstFocusableElement.focus();
      e.preventDefault();
    }
  };

  if ($modal.classList.contains("with-navigation")) {
    $modal.removeEventListener("keydown", callback);
  }

  $modal.addEventListener("keydown", callback);
  $modal.classList.add("with-navigation");
};

const initModalNavigation = () => {
  const exclude = ["product-color-picker"];
  let $trigger = null;

  $(document.documentElement).on("shown.bs.modal", ({ target: $modal }) => {
    $trigger = document.activeElement;

    if (!($modal instanceof HTMLElement)) return;
    if ($modal.classList.contains("with-navigation")) return;

    // skip some modals
    const excluded = exclude.some(className => $modal.classList.contains(className));
    if (excluded) return;

    applyModalNavigation($modal);
  });

  $(document.documentElement).on("hidden.bs.modal", () => {
    $trigger?.focus();
    $trigger = null;
  });
};

export default () => {
  if (isTouchDevice()) return;

  initRadioButtonsControl();
  initModalNavigation();
};
