import MixpanelEventClass from "common/utils/ui/mixpanel-event-class";
import "./component.scss";

const initMixPanelEvents = () =>
  new MixpanelEventClass({
    eventSelector: ".js-kit-breadcrumbs-item",
    mixpanelEventOptions: {
      eventName: "breadcrumbs_click"
    },
    attributesToContext: {
      itemIndex: "item_index",
      itemCaption: "item"
    }
  });

initMixPanelEvents();
