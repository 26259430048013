/* eslint-disable max-lines-per-function */
import ToggleClass from "common/utils/ui/toggle-class";
import { initMobileNavigation as initTransfersNavigation } from "components/masthead/transfers_navigation/component";
import "components/masthead/stickers_navigation/component";
import "components/masthead/spangles_navigation/component";
import MobileMenuClass from "./mobile-menu-class";
import AbMobileMenuClass from "./ab_mobile-menu-class";
import InitHeculesButton from "../mobile-menu-hercules/mobile-menu-hercules";
import "./mobile-menu-banner/mobile-menu-banner.scss";
import "./mobile-menu.scss";

const toggleMasthead = () => {
  const $icons = document.querySelectorAll(".masthead__icon");
  $icons.forEach($icon => $icon.classList.toggle("masthead__icon--hidden"));
};

export default () => {
  const showHamburgerMenuRedesign = Boolean(
    document.querySelector(".js-mobile-menu.ab_hamburger_menu_redesign")
  );

  const mobileMenu = showHamburgerMenuRedesign
    ? new AbMobileMenuClass({
        closeMenuSelector: ".js-masthead-mobile-filters-toggler, .js-filters-back",
        contentWrapperSelector: ".js-mobile-menu-content",
        bannerWrapperSelector: ".js-mobile-menu-banner",
        menuWrapperSelector: ".js-mobile-menu",
        menuCaptionsSelector: ".js-mobile-menu-caption",
        submenuTriggerButtonSelector: ".js-mobile-submenu-trigger",
        buttonActiveClass: "mobile-menu__list-button--active",
        documentLockedClass: "locked-mobile",
        submenuActiveClass: "mobile-menu--with-submenu",
        openMenuClass: "opened"
      })
    : new MobileMenuClass({
        closeMenuSelector: ".js-masthead-mobile-filters-toggler, .js-filters-back",
        contentWrapperSelector: ".js-mobile-menu-content",
        bannerWrapperSelector: ".js-mobile-menu-banner",
        menuWrapperSelector: ".js-mobile-menu",
        submenuTriggerButtonSelector: ".js-mobile-submenu-trigger",
        buttonActiveClass: "mobile-menu__list-button--active",
        documentLockedClass: "locked-mobile",
        submenuActiveClass: "mobile-menu--with-submenu",
        openMenuClass: "opened"
      });

  const toggle = new ToggleClass({
    toggleSelector: ".js-masthead-mobile-bar-toggler, .js-masthead-menu-toggler"
  });

  toggle.onToggleCallback(() => {
    toggleMasthead();
    mobileMenu.toggleModal();
  });

  mobileMenu.loadMenu(); // Initial loading menu
  InitHeculesButton();
  initTransfersNavigation();
};
