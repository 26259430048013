import { on as onEvent } from "common/utils/ui/event";
import { isFunction } from "common/utils/helpers/is";

/**
 * @deprecated Due to inconsistency class name please use EventClass instead.
 */
export default class ToggleClass {
  constructor(
    props = {
      toggleSelector: ""
    }
  ) {
    this.toggleCallback = null;

    onEvent("click", props.toggleSelector, this.toggle.bind(this), true);
  }

  onToggleCallback(callback) {
    this.toggleCallback = callback;

    return this;
  }

  toggle() {
    isFunction(this.toggleCallback) && this.toggleCallback();

    return this;
  }
}
