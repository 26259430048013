import mobileApp from "common/utils/mobile/app";
import AppRegistry from "./AppRegistry";
import AppRegistryLoader from "./AppRegistryLoader";

const appRegistry = new AppRegistry(AppRegistryLoader);
appRegistry.subscribe(event => {
  // publish event only when critical resources are loaded
  if (event.data.loaded) {
    mobileApp.mq.publish("app-registry", event);
  }
});

export default appRegistry;
