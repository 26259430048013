import { sendDataLayer } from "jiffy-analytics";
import "./social-icons.scss";

export default () => {
  const links = document.querySelectorAll(".js-social-link");

  links.forEach(link => {
    link.addEventListener("click", () => {
      const { gaAction } = link.dataset;
      sendDataLayer({
        genericEvent: true,
        eventCategory: "Social Networks",
        event: gaAction
      });
    });
  });
};
