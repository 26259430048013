/* eslint-disable max-lines-per-function */
import Slider from "bootstrap-slider";
import { getQueryParams, isMobile, pushUrl } from "common/utils/browser";
import { paramToMaterial, materialToParam } from "./utils";
import "./products-primary-material-filter.scss";

// Getters
const getTargetClass = () =>
  isMobile() ? ".js-material-filter-mobile" : ".js-material-filter-desktop";
const getSliderWindow = () => (isMobile() ? "mobileMaterialSlider" : "desktopMaterialSlider");
const $getSliderEl = () => document.querySelector(`${getTargetClass()} .js-value-slider`);
const $getBtnApply = () => document.querySelector(`${getTargetClass()} .js-control-button-apply`);
const $getBtnClear = () => document.querySelector(`${getTargetClass()} .js-control-button-clear`);
const $getLegend = () => document.querySelector(`${getTargetClass()} .js-value-slider-range`);
const $getMaterialSelector = () =>
  document.querySelector(`${getTargetClass()} .js-material-selector`);
const $getProductSection = () =>
  document.querySelector(`${getTargetClass()} .js-products-zero-result-title`);
const $getMaterialDynamicEl = () => document.querySelector(".js-material-filters-dynamic");
const getMaterialOptions = () =>
  [...$getMaterialSelector().options].map(x => x.value).filter(x => x !== "");
// eslint-disable-next-line no-return-assign
const getSlider = () =>
  (window[getSliderWindow()] =
    window[getSliderWindow()] || new Slider($getSliderEl(), { natural_arrow_keys: true }));

// Setters
const setLoadingState = show => {
  const $backdrop = document.querySelector(
    `${getTargetClass()} .products-primary-material-filter__container-backdrop`
  );

  $backdrop.classList.toggle("hidden", !show);
};

const setSliderState = enable => {
  const slider = getSlider();
  if (enable) slider.enable();
  else slider.disable();

  $getLegend().classList.toggle("disabled", !enable);
};

// eslint-disable-next-line no-return-assign
const setApplyActionButtonState = enable => ($getBtnApply().disabled = !enable);

const setClearActionButtonState = enable =>
  $getBtnClear().classList.toggle("hide-with-space", !enable);

const setInitialStates = () => {
  const args = getQueryParams();
  let needToClear = false;

  Object.keys(args).forEach(key => {
    const material = paramToMaterial(key);

    if (getMaterialOptions().includes(material)) {
      $getMaterialSelector().value = material;

      const decodedAmount = decodeURIComponent(args[key]);
      const amount = decodedAmount.split(",");
      const minAmount = amount[0];
      getSlider().setValue(minAmount);

      window.initialMaterialMinAmount = Number(minAmount);
      window.initialMaterialName = material;

      needToClear = true;
    }
  });

  // ensures the clear action button gets rendered and available before set its state
  if (needToClear) setTimeout(() => setClearActionButtonState(true), 50);
};

// Functions
const handleMaterialChange = _ => {
  const { value } = $getMaterialSelector();
  setSliderState(!!value);

  // disable apply button if the slider value or selected material is equal to its current value
  const sliderValue = getSlider().getValue();
  updateApplyButtonState(sliderValue, value);
};

const handleSliderChange = value => {
  // change value range label
  $getLegend().innerHTML = `${value}% <span class="products-primary-material-filter__value-slider__dash">&ndash;</span> 100%`;

  // disable apply button if the slider value or selected material is equal to its current value
  const material = $getMaterialSelector().value;
  updateApplyButtonState(value, material);
};

const performRedirection = args => {
  const params = new URLSearchParams(args);

  // if there is no products found for query
  if ($getProductSection()) {
    params.delete("query");
  }

  // reset pagination
  params.delete("page");
  params.delete("per_page");

  // ensures the user feedback shown before redirecting to new path
  setTimeout(() => {
    if ($getMaterialDynamicEl()) pushUrl("/");

    window.location.search = params;
  }, 50);
};

const clearedMaterialParams = () => {
  if ($getMaterialDynamicEl()) return {};

  const args = getQueryParams();

  Object.keys(args).forEach(key => {
    const material = paramToMaterial(key);
    if (getMaterialOptions().includes(material)) delete args[key];
  });

  return args;
};

const updateApplyButtonState = (sliderValue, material) => {
  let disabled;

  // this means the filter already applied
  if (window.initialMaterialMinAmount && window.initialMaterialName) {
    disabled =
      material === "" ||
      (sliderValue === window.initialMaterialMinAmount && material === window.initialMaterialName);
  } else {
    disabled = sliderValue === 0 || material === "";
  }

  setApplyActionButtonState(!disabled);
};

// Inits
const initSliderState = () => {
  const slider = getSlider();
  slider.on("slide", handleSliderChange);
  slider.on("slideStop", handleSliderChange);
  handleSliderChange(slider.getValue());
};

const initDomHandles = () => {
  const $materialSelector = $getMaterialSelector();
  const slider = getSlider();

  $getBtnApply()?.addEventListener("click", () => {
    const $sliderEl = $getSliderEl();

    setLoadingState(true);

    const sliderValue = slider.getValue();
    const args = clearedMaterialParams();
    const sliderMax = Number($sliderEl.dataset.sliderMax);
    const selectedMaterial = $materialSelector.value;
    const paramName = materialToParam(selectedMaterial);

    args[paramName] = [sliderValue, sliderMax].join(",");
    performRedirection(args);
  });

  $getBtnClear()?.addEventListener("click", () => {
    setLoadingState(true);
    const args = clearedMaterialParams();
    performRedirection(args);
  });

  $materialSelector.addEventListener("change", handleMaterialChange);
};

const initProductsPrimaryMaterialFilter = () => {
  const $materialSelector = $getMaterialSelector();
  if (!$materialSelector) return;

  setInitialStates();
  setLoadingState(false);
  setSliderState(false);
  setApplyActionButtonState(false);
  setClearActionButtonState(false);
  handleMaterialChange(null);

  initDomHandles();
  initSliderState();
};

// Init events
export default initProductsPrimaryMaterialFilter;
