/* eslint-disable import/prefer-default-export */
/**
 * Computes upload rate in megabits per second for a given file size in bytes
 * @param {number} bytes - file size in bytes
 * @param {double} uploadStartTime - upload start time in DOMHighResTimeStamp
 * @param {double} uploadEndTime - upload end time in DOMHighResTimeStamp
 * @returns {double|number} megabits per second without rounding or zero
 */
export const megabitsPerSecond = (bytes, uploadStartTime, uploadEndTime) => {
  if (!uploadStartTime || !uploadEndTime) return 0;

  const durationInSeconds = (uploadEndTime - uploadStartTime) / 1000;
  const fileSizeInBits = bytes * 8;
  return fileSizeInBits / durationInSeconds / 1e6;
};
