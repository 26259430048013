import { api } from "jiffy-api";
import { track } from "jiffy-analytics";
import { redirectTo } from "common/utils/browser";
import "./component.scss";

const $$getModal = () => $(document.querySelector(".js-rebrand-announcement-modal"));
const $getAction = () => document.querySelector(".js-rebrand-announcement-modal-action");

const init = () => {
  const $action = $getAction();
  const $$modal = $$getModal();

  if (!$action) return;

  $action.addEventListener("click", async () => {
    try {
      $action.setAttribute("disabled", true);
      const { url } = await api.jiffyshirts.rebrand.getRedirectUrl();
      track("redirect_to_jiffy");
      $$modal.modal("hide");
      redirectTo(url);
    } catch (_error) {
      $action.removeAttribute("disabled");
    }
  });

  $$modal.modal("show");
};

export default init;
