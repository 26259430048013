import { api } from "jiffy-api";
import { on as onEvent } from "common/utils/ui/event";
import { isPhone, isMobileBrowser } from "common/utils/browser";
import { initMixpanelEvents } from "common/utils/mixpanel/init-mixpanel-events";

export default class MobileMenuClass {
  constructor(
    props = {
      closeMenuSelector: "",
      contentWrapperSelector: "",
      bannerWrapperSelector: "",
      menuWrapperSelector: "",
      submenuTriggerButtonSelector: "",
      buttonActiveClass: "",
      documentLockedClass: "",
      submenuActiveClass: "",
      openMenuClass: ""
    }
  ) {
    this.$menuWrapper = document.querySelector(props.menuWrapperSelector);
    this.$contentWrapper = document.querySelector(props.contentWrapperSelector);
    this.$bannerWrapper = document.querySelector(props.bannerWrapperSelector);
    this.scrollPosition = {};
    this.props = props;
    this.isOpenModal = false;
    onEvent("click", props.submenuTriggerButtonSelector, this.onMenuTriggerClick.bind(this), true);
    onEvent("click", props.closeMenuSelector, this.closeModal.bind(this), true);
  }

  $getAllActiveTriggerButtons() {
    return this.$menuWrapper.querySelectorAll(
      `${this.props.submenuTriggerButtonSelector}.${this.props.buttonActiveClass}`
    );
  }

  onMenuTriggerClick({ target: $target }) {
    const $button =
      $target.tagName === "BUTTON"
        ? $target
        : $target.closest(this.props.submenuTriggerButtonSelector);
    const { direction, level } = $button.dataset;

    if (direction === "back") {
      const $submenus = this.$getAllActiveTriggerButtons();

      const $lastSubmenu = $submenus[$submenus.length - 1];
      $lastSubmenu.classList.remove(this.props.buttonActiveClass);

      if (level === "1") {
        this.$menuWrapper.classList.remove(this.props.submenuActiveClass);
      }

      const prevLevel = String(level - 1);
      this.$menuWrapper.scroll(0, this.scrollPosition[prevLevel]);
    } else {
      $button.classList.add(this.props.buttonActiveClass);
      this.scrollPosition[level] = this.$menuWrapper.scrollTop;
      this.$menuWrapper.scroll(0, 0);

      if (level === "0") {
        this.$menuWrapper.classList.add(this.props.submenuActiveClass);
      }
    }

    const currentTransition = -100 * level;
    const shift = direction === "forward" ? -100 : 100;
    this.$contentWrapper.style.transform = `translate3d(${currentTransition + shift}%, 0, 0)`;
  }

  updateMenuWithHtml({ html }) {
    this.$contentWrapper.innerHTML = html;

    initMixpanelEvents(".mobile-menu", "[data-mixpanel-event][data-mixpanel-listen]");

    return this;
  }

  updateBannerWithHtml({ html }) {
    this.$bannerWrapper.innerHTML = html;

    return this;
  }

  loadMenu() {
    const isMobile = () => isPhone() || isMobileBrowser();
    const getMenu = () => {
      if (!isMobile()) return;
      api.menu.fetchMobileMenu().then(this.updateMenuWithHtml.bind(this));
      api.menu.fetchMobileBanner().then(this.updateBannerWithHtml.bind(this));
      window.removeEventListener("resize", getMenu, true);
    };

    getMenu();
    !isMobile() && window.addEventListener("resize", getMenu, true);

    return this;
  }

  closeModal() {
    this.scrollPosition = {};
    this.$menuWrapper.scroll(0, 0);
    this.$contentWrapper.style.transform = `translate3d(0, 0, 0)`;
    this.$getAllActiveTriggerButtons().forEach($submenu =>
      $submenu.classList.remove(this.props.buttonActiveClass)
    );
    this.$menuWrapper.classList.remove(this.props.openMenuClass);
    document.documentElement.classList.remove(this.props.documentLockedClass);
    this.isOpenModal = false;

    return this;
  }

  openModal() {
    this.$menuWrapper.classList.add(this.props.openMenuClass);
    document.documentElement.classList.add(this.props.documentLockedClass);
    this.isOpenModal = true;

    return this;
  }

  toggleModal() {
    return this.isOpenModal ? this.closeModal() : this.openModal();
  }
}
