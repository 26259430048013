// https://stackoverflow.com/questions/33625485/equivalent-of-jquery-active-for-pure-javascript
/* eslint-disable */
window.ajax_active = 0;
(open => {
  return XMLHttpRequest.prototype.open = function(method, url, async, user, pass) {
    this.addEventListener("readystatechange", (function() {
      if (this.readyState === 1) {
        window.ajax_active += 1;
      } else if (this.readyState === 4){
        window.ajax_active -= 1;
      }
    }), false);
    return open.call(this, method, url, async, user, pass);
  };
})(XMLHttpRequest.prototype.open);
/* eslint-enable */
