import { sendDataLayerOnLinkClickEvent } from "jiffy-analytics";
import { pushUrl, isNewTab } from "common/utils/browser";
import "./product-details-back.scss";

const $getBackBlock = () => document.querySelector(".js-product-details-back");
const $getBackLink = () => $getBackBlock()?.querySelector(".js-product-details-back-link");

export const $getBackToResultLink = () => {
  const $link = Array.from(document.querySelectorAll(".js-product-card")).find($element =>
    document.referrer.includes($element.getAttribute("href"))
  );
  const hasBackToResult = document.location.hash.includes("back-to-results");

  return !hasBackToResult || !$link || isNewTab() ? null : $link;
};

export const initReferFromProductDetails = () => {
  const $link = $getBackToResultLink();
  if (!$link) return;

  setTimeout(() => {
    $link.scrollIntoView();
  }, 0);
};

const initAnalytics = e => {
  pushUrl(window.location.href);
  sendDataLayerOnLinkClickEvent(e, {
    genericEvent: true,
    eventCategory: "Product page",
    event: "click",
    eventLabel: "Back to search results",
    eventValue: 1
  });
};

const getReferrerPath = () => {
  const { referrer } = document;
  const referrerPath = referrer.replace(window.location.origin, "");
  // if `referrerPath` length was changed it means a referrer is current site
  // else a referrer is third party site, so back button doesn't make sense
  return referrerPath.length !== referrer.length ? `${referrerPath}#back-to-results` : null;
};

export default () => {
  const $backBlock = $getBackBlock();
  const $backLink = $getBackLink();
  const referrerPath = getReferrerPath();
  if (!$backBlock || !$backLink || !referrerPath) return;

  $backBlock.classList.remove("hidden");
  $backLink.addEventListener("click", initAnalytics);
  $backLink.setAttribute("href", referrerPath);
};
