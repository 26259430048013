import { writeCookie, readCookie, deleteCookie } from "common/utils/cookie";
import { STORE_BASE_PATH } from "common/utils/config";

// 30 seconds
const CACHE_TTL = 30;
const PREFIX = "__JIFFY_PDP_DATE__";

function getCacheProductColorDeliveryEstimate() {
  const data = readCookie(PREFIX);
  let result;

  try {
    result = JSON.parse(data);
  } catch (e) {
    result = [];
  }

  return result;
}

/**
 * Generate cookie name with defined prefix
 * @param productName {string}
 * @param selectedColor {string}
 * @returns "fruitoftheloom-3931_True_Red" {string}
 */
export const buildDataName = (productName, compositionName) => {
  const composition = compositionName.replace(/\s+/g, "_");
  const productSlug = productName.replace(/\s+/g, "_");
  return `${productSlug}_${composition}`;
};

/**
 * The function creates a cookie with delivery estimate object, with expiration set by CACHE_TTL
 * @param $productCard {DOMElement}
 */
export const cacheDeliveryEstimate = $productCard => {
  const $getDeliveryDate = $productTile => $productTile.querySelector(".js-plp-delivery-date");
  const estimate = $getDeliveryDate($productCard)?.dataset || {};

  // Do not store cookie if CA store
  if (STORE_BASE_PATH === "/ca") return;
  // not yet rendered
  if (estimate.timeleft === undefined) return;
  // compare if timeleft is 5 minutes or less
  const curTime = Date.now();
  const cutoff = new Date(estimate.timeleft).getTime();
  const diff = Math.floor((cutoff - curTime) / 1000);
  if (diff <= CACHE_TTL) return;

  const { slug, composition } = $productCard.dataset;
  const name = buildDataName(slug, composition);
  const deliveryDate = [name, estimate.date, estimate.timeleft];

  cacheProductColorDeliveryEstimate(slug, composition, deliveryDate);
};

export const cacheProductColorDeliveryEstimate = (productSlug, colorName, data) => {
  const dataArray = getCacheProductColorDeliveryEstimate();
  const dataIndex = dataArray.findIndex(element => element[0] === data[0]);

  // Remove the previous delivery estimate data of the given product-composition
  if (dataIndex !== -1) {
    dataArray.splice(dataIndex, 1);
  }

  // Data array should only have a length of 10
  // Before adding an element remove first in element
  if (dataArray.length === 10) {
    dataArray.shift();
  }

  dataArray.push(data);

  writeCookie(PREFIX, JSON.stringify(dataArray), CACHE_TTL);
};

export const findCacheProductColorDeliveryEstimate = (productSlug, colorName) => {
  const name = buildDataName(productSlug, colorName);
  const dataArray = getCacheProductColorDeliveryEstimate();
  const data = dataArray.find(element => element[0] === name);

  if (data) {
    return {
      success: true,
      composition_dates: {
        show_logo: false,
        value_full: data[1],
        timeleft: data[2]
      },
      cart_dates: {
        timeleft: data[2],
        shipments: [{ date: data[1] }]
      },
      mixpanel: typeof data[3] === "object" ? data[3] : undefined
    };
  }

  return false;
};

export const clearCachedEstimates = () => {
  deleteCookie(PREFIX);
};

export default {};
