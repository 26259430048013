import { submitDtfEvent } from "common/utils/mixpanel/submit-dtf-events";
import { BUSINESS_LINE } from "common/utils/app-registry/contants";

const dropZoneUnsupportedFileType = response => response === "You can't upload files of this type.";

const dropZoneNetworkError = response => response === "Server responded with 0 code.";

const axiosNetworkError = error => error.name === "AxiosError" && error.message === "Network Error";

const fetchErrors = response => ["Load failed", "Failed to fetch"].includes(response?.message);

const getUploadErrorEventName = errorType => {
  const errorMap = {
    backend_file_validation: "image_validation_error",
    image_processing_error: "image_upload_process_error",
    current_pdp_date_json_error: "image_upload_process_error",
    product_prices_json_error: "image_upload_process_error"
  };

  return errorMap[errorType] || "image_upload_error";
};

const generateUniqueId = () =>
  Math.random().toString(36).substring(2) + new Date().getTime().toString(36);

const logImageUploadError = error => {
  const logId = generateUniqueId();

  window.Honeybadger?.notify("Image upload error", {
    params: {
      error: {
        message: error.message,
        stack: error.stack,
        name: error.name
      },
      logId
    }
  });

  return logId;
};

export const getErrorType = response => {
  let errorType;
  if (dropZoneUnsupportedFileType(response)) {
    errorType = "file type is not allowed";
  } else if (fetchErrors(response)) {
    errorType = response?.name;
  } else {
    errorType = response?.error_type || "unhandled_error";
  }

  return errorType;
};

/* eslint-disable max-lines-per-function */
export const getErrorContext = (pageInfo, file, error) => {
  let response;
  let errorMessageValue;
  let errorUUID;
  let errorDetails;
  const dpiValue = 300;

  if (error.response) {
    response = error.response.data;

    if (typeof response === "string") {
      errorMessageValue = response;
    } else {
      errorMessageValue = response.error;
      errorDetails = response.error_details;
      errorUUID = response.error_uuid;
    }
  } else {
    response = error;
    errorMessageValue = error?.message || error;
  }

  const errorTypeValue = getErrorType(response);

  return {
    error_type: errorTypeValue,
    error_message: errorMessageValue,
    progress_message: error?.progressMessage,
    error_uuid: errorUUID,
    error_status: errorDetails?.status,
    error_stderr: errorDetails?.stderr,
    file_name: file.name,
    file_type: file.type,
    file_size: file.size,
    width: (file.width / dpiValue).toFixed(2),
    height: (file.height / dpiValue).toFixed(2),
    resolution_width: file.width,
    resolution_height: file.height,
    dpi: dpiValue,
    page: pageInfo.pageValue,
    business_line: pageInfo.businessLine || BUSINESS_LINE.DTF,
    pdf_page_count: errorDetails?.pdf_page_count,
    project_id: null,
    response,
    ...(pageInfo.orderType && { order_type: pageInfo.orderType })
  };
};
/* eslint-enable max-lines-per-function */

export const logUploadErrorEvent = (pageInfo, file, error) => {
  let errorEvent;
  const response = error.response ? error.response.data : error;

  // TODO:
  // Need to organize different type of image upload errors better
  if (dropZoneUnsupportedFileType(response)) {
    errorEvent = "image_upload_unsupported_file";
  } else if (dropZoneNetworkError(response) || axiosNetworkError(error)) {
    errorEvent = "image_upload_network_error";
  } else if (fetchErrors(response)) {
    errorEvent = "image_fetch_error";
  } else {
    errorEvent = getUploadErrorEventName(response.error_type);
  }

  const errorContext = getErrorContext(pageInfo, file, error);
  if (errorEvent === "image_upload_error") {
    errorContext.log_id = logImageUploadError(error);
  }

  submitDtfEvent(errorEvent, errorContext);
};

export const getUploadErrorMessage = response => {
  const responseError = typeof response === "string" ? response : response.error;
  return responseError || "Something went wrong. Please, try again";
};
