/* eslint-disable camelcase */
/**
 * Logs an upload artwork error to Honeybadger.
 *
 * @param {object} payload - The payload containing error information.
 * @param {string} payload.error_message - The error message.
 * @param {string} payload.error - The error description.
 * @param {string} payload.handledError - The error is handled by backend validation.
 * @param {string} payload.uploadError - The error description for the upload.
 */
// eslint-disable-next-line import/prefer-default-export
export const logUploadError = payload => {
  const { error_message, error, handledError, uploadError, errorResponse } = payload;
  // Known errors that are not worth sending to Honeybadger
  // i.e. "Network Error" is not code related, but a network issue
  const knownErrors = ["Something went wrong while processing your artwork", "Network Error"];

  if (knownErrors.includes(error) || uploadError === "Processing error" || handledError) return;

  window.Honeybadger?.notify("Upload artwork error", {
    params: {
      error_message,
      error,
      uploadError,
      errorResponse
    }
  });
};
/* eslint-enable camelcase */
