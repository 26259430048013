import { submitDtfEvent } from "common/utils/mixpanel/submit-dtf-events";
import { track } from "common/utils/mixpanel/tracker";
import initSubsiteNavigation from "components/jiffy/masthead-subsite-navigation/masthead-subsite-navigation";
import { initDropdownNavigation } from "common/jiffy/accessibility";
import "./masthead-menu.scss";

const modifiers = {
  dropdownOpen: "masthead-menu__inner--open"
};

const selectors = {
  iconDefault: ".js-menu-icon-default",
  iconSpinner: ".js-menu-icon-spinner",
  colorFilterTrigger: ".js-filter-by-color",
  mastheadDropdownContent: ".js-masthead-dropdown-content",
  mastheadDropdownTrigger: ".js-masthead-dropdown-trigger",
  transfersLink: ".masthead-menu__item--transfers",
  freeFontsLink: ".masthead-menu__item--free-fonts"
};

const trackFreeFontsLink = () => {
  const $freeFontsLink = document.querySelector(selectors.freeFontsLink);
  if (!$freeFontsLink) return;

  $freeFontsLink.addEventListener("click", () => {
    track("borneo_editor_open_button_clicked", { source: "masthead" });
  });
};

const initFilterByColorHandler = () => {
  const $triggers = document.querySelectorAll(selectors.colorFilterTrigger);
  const handleTrigger = () => {
    localStorage.setItem("scrollTo", "#products-top-filters");
    document.querySelector(selectors.iconDefault).classList.add("hidden");
    document.querySelector(selectors.iconSpinner).classList.remove("hidden");
  };

  $triggers.forEach($trigger => $trigger.addEventListener("click", handleTrigger));
};

const trackTransfersLink = () => {
  const $transfersLink = document.querySelector(selectors.transfersLink);
  if (!$transfersLink) return;

  $transfersLink.addEventListener("click", () => {
    submitDtfEvent("transfers_button_clicked");
  });
};

export default () => {
  initFilterByColorHandler();
  initSubsiteNavigation();
  initDropdownNavigation({
    openClass: modifiers.dropdownOpen,
    triggerClass: selectors.mastheadDropdownTrigger
  });
  trackTransfersLink();
  trackFreeFontsLink();
};
