import AppRegistryModules from "./AppRegistryModules";
import { MODULE_STATE, CRITICAL_MODULES } from "./contants";

export default class AppRegistry {
  modules = new AppRegistryModules(this.notify.bind(this));

  listeners = [];

  constructor(loader) {
    this.loader = loader;
  }

  register(module, ...params) {
    if (Array.isArray(module)) {
      module.forEach(m => this.registerModule(m.name, m.fn));
    } else {
      this.registerModule(module, ...params);
    }
  }

  async registerModule(moduleName, initFn) {
    this.modules.change(moduleName, MODULE_STATE.INIT);
    await this.loader.load(initFn);
    this.modules.change(moduleName, MODULE_STATE.LOADED);
  }

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      const index = this.listeners.indexOf(listener);
      if (index > -1) {
        this.listeners.splice(index, 1);
      }
    };
  }

  notify(event) {
    const criticalModulesLoaded = CRITICAL_MODULES.every(module =>
      (event.data.state.state[MODULE_STATE.LOADED] || []).includes(module)
    );
    this.listeners.forEach(listener =>
      listener({ ...event, data: { ...event.data, loaded: criticalModulesLoaded } })
    );
  }
}
