/* eslint-disable import/prefer-default-export */
/**
 * Splits source array into chunkSize
 * @param array
 * @param chunkSize
 * @returns [[1, 2], [3, 4]]
 */
export const chunkArray = (array, chunkSize) =>
  Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
    array.slice(index * chunkSize, (index + 1) * chunkSize)
  );
