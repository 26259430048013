import { sendDataLayer } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";
import "./masthead-search-validation-popup/masthead-search-validation-popup";
import "./masthead-search.scss";

const $getValidationPopups = () =>
  document.querySelectorAll(".js-masthead-search-validation-popup");

const fillSearchBarFromUrlQuery = () => {
  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  const $searchBarInputs = document.querySelectorAll(".masthead-search__form input");
  if (queryParams.query) {
    $searchBarInputs.forEach($input => {
      $input.value = queryParams.query;
    });
  }
};

const showValidationPopups = () => {
  const $validationPopups = $getValidationPopups();
  $validationPopups.forEach($popup => {
    $popup.classList.add("masthead-search-validation-popup--show");
  });
};

const hideValidationPopups = () => {
  const $validationPopups = $getValidationPopups();
  $validationPopups.forEach($popup => {
    $popup.classList.remove("masthead-search-validation-popup--show");
  });
};

const initCustomValidityMessage = () => {
  let timeoutRef = null;

  const triggerValidation = () => {
    if (timeoutRef) {
      return;
    }
    showValidationPopups();
    timeoutRef = setTimeout(() => {
      hideValidationPopups();
      timeoutRef = null;
    }, 3000);
  };

  onEvent(
    "invalid",
    ".js-masthead-search__form input",
    event => {
      event.preventDefault();
      triggerValidation();
    },
    true
  );

  onEvent("focus", ".js-masthead-search__input", () => {
    hideValidationPopups();
    clearTimeout(timeoutRef);
    timeoutRef = null;
  });
};

export default () => {
  $(".js-masthead-search__form").on("submit", () => {
    sendDataLayer({ event: "Search" });
  });

  fillSearchBarFromUrlQuery();
  initCustomValidityMessage();
};
