import "./masthead-account.scss";
import { track } from "jiffy-analytics";
import initMastheadAccountPopup from "./masthead-account-popup/masthead-account-popup";

const SELECTORS = {
  JIFFYHUB_LINK: ".js-masthead-jiffyhub-link"
};

const trackJiffyHubLink = () => {
  const $jiffyhubLink = document.querySelector(SELECTORS.JIFFYHUB_LINK);
  if (!$jiffyhubLink) return;

  $jiffyhubLink.addEventListener("click", () => {
    track("jiffyhub_masthead_logo_clicked");
  });
};

const initMastheadAccount = () => {
  initMastheadAccountPopup();
  trackJiffyHubLink();
};

export default initMastheadAccount;
