import { on as onEvent } from "common/utils/ui/event";
import { trackEvent } from "./product-color-picker-tracker";

export default class ProductColorPickerVisibilityClass {
  constructor(
    checkbox,
    visibilitySelectors,
    options = {
      toggleVisibilityClassName: "",
      getTrackElements: () => ({}),
      checkboxParentSelector: "",
      eventCallback: () => {}
    }
  ) {
    this.visibilitySelectors = visibilitySelectors;
    this.$checkbox = document.querySelector(checkbox);
    this.$checkboxParent = this.$checkbox?.closest(options.checkboxParentSelector);
    this.options = options;

    onEvent("change", checkbox, e => this.onChange(e), true);
  }

  isCheckBoxExist() {
    return !!this.$checkbox;
  }

  getCheckboxState() {
    return this.$checkbox.checked;
  }

  setVisibility(flag) {
    document.querySelectorAll(this.visibilitySelectors).forEach($el => {
      $el.classList.toggle(this.options.toggleVisibilityClassName, flag);
    });

    return this;
  }

  onChange({ target: $target }) {
    this.setVisibility($target.checked);
    trackEvent("color_change_modal_switch_toggle", this.options.getTrackElements());
    this.options.eventCallback(this.getCheckboxState());

    return this;
  }

  reset() {
    if (!this.isCheckBoxExist()) return null;

    this.$checkbox.checked = false;
    this.setVisibility(false);
    this.options.eventCallback(this.getCheckboxState());

    return this;
  }

  updateButtonVisibility() {
    const elementsCount = document.querySelectorAll(this.visibilitySelectors).length;
    this.$checkboxParent?.classList.toggle("hidden", elementsCount === 0);

    return this;
  }
}
