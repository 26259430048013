import { track } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";
import { upsertQueryParam } from "common/utils/browser";
import { createInput } from "common/utils/form/element";
import "./products-top-filters.scss";

const $getOutOfStockHandler = () => document.querySelector(".js-products-hide-oos");
const $getSortSelectHandler = () => document.querySelector(".js-products-top-filters__select-box");
const $getTopFilterHandler = () => document.getElementById("products-top-filters");
const $getSizesHandler = () => $getTopFilterHandler().querySelectorAll(".js-size-filter-checkbox");
const $getColorsHandler = () =>
  $getTopFilterHandler().querySelectorAll(".js-products-color-checkbox");
const $getColorSelectDefaultHandler = () => document.querySelector(".js-products-color-default");
const $getColorSelectedHandler = () => document.querySelector(".js-products-color-selected");
const $getColorSelectedCountHandler = () =>
  document.querySelector(".js-products-color-selected-count");
const $getLoaderHandler = () => document.querySelector(".js-top-filters-colors-search-loader");

// This form is included either in original search form (".js-products-top-filters-form")
// or Quick Style Finder form (".js-products-extended-filter-form")
const $getFormHandler = () =>
  document.querySelector(".js-products-top-filters-form") ||
  document.querySelector(".js-products-extended-filter-form");

const trackFilter = () => {
  const trackInputFilters = (elementSelector, event) => {
    const callback = ({ target: $target }) => {
      if ($target.checked) {
        track(event, {
          select_size: $target.value
        });
      } else {
        track(event, {
          deselect_size: $target.value
        });
      }
    };

    onEvent("click", elementSelector, callback, true);
  };

  trackInputFilters(".js-products-color-checkbox", "color_and_size_quick_search_color");
  trackInputFilters(".js-size-filter-checkbox", "color_and_size_quick_search_size");
};

const initSearchLoader = () => {
  const reverseBooleanString = str => (str && str === "false" ? "true" : "false");

  const additionalParams = {
    show_oos: reverseBooleanString($getOutOfStockHandler()?.value),
    sort: $getSortSelectHandler()?.value
  };

  const $form = $getFormHandler();
  if ($form) {
    Object.keys(additionalParams).forEach(key => {
      if (additionalParams[key]) {
        const $newInput = createInput("hidden", key, additionalParams[key]);
        $form.appendChild($newInput);
      }
    });
  }

  const getFilterValues = $elements =>
    Array.from($elements)
      .filter(({ checked }) => checked)
      .map(({ value }) => value);

  const trackApplyFilters = () => {
    track("color_and_size_quick_search_applied", {
      sizes: getFilterValues($getSizesHandler()),
      colors: getFilterValues($getColorsHandler())
    });
  };

  const handleSearchTriggerClick = () => {
    $getLoaderHandler().classList.remove("hidden");
    localStorage.setItem("scrollTo", "#products-top-filters");

    trackApplyFilters();
    $form?.submit();
  };

  const $searchTriggers = document.querySelectorAll(".js-colors-sizes-search-trigger");

  $searchTriggers.forEach($trigger => {
    $trigger.addEventListener("click", handleSearchTriggerClick);
  });
};

const observeScrollToAction = () => {
  const target = localStorage.getItem("scrollTo");
  if (target !== "#products-top-filters") return;

  const $target = document.querySelector(target);
  // if the page is loaded in an iframe, scrollIntoView is working incorrectly
  // cause parent window is modified the page template and we have to wait
  // for the page to be fully builded
  const isIframe = window.self !== window.top;
  const timeout = isIframe ? 1000 : 0;

  setTimeout(() => {
    $target?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, timeout);

  localStorage.removeItem("scrollTo");
};

const initColorCheckboxes = () => {
  const $checkboxes = document.querySelectorAll(".js-products-color-checkbox");

  const handleCheckboxOnChange = () => {
    const count = document.querySelectorAll(".js-products-color-checkbox:checked").length;

    $getColorSelectDefaultHandler().classList.toggle("hidden", count > 0);
    $getColorSelectedHandler().classList.toggle("hidden", count === 0);
    $getColorSelectedCountHandler().innerText = count;
  };

  $checkboxes.forEach($checkbox => {
    $checkbox.addEventListener("change", handleCheckboxOnChange);
  });
};

const initOutOfStockHandler = () => {
  const $handler = $getOutOfStockHandler();

  $handler?.addEventListener("change", () => {
    localStorage.setItem("scrollTo", "#products-top-filters");

    const { name } = $handler.dataset;
    setTimeout(() => {
      upsertQueryParam(name, `${$handler.value}`);
    }, 400);
  });
};

const initSortSelectHandler = () => {
  const handleSelectChange = e => {
    upsertQueryParam("sort", e.currentTarget.value);
  };

  $getSortSelectHandler()?.addEventListener("change", handleSelectChange);
};

export default () => {
  initSortSelectHandler();
  initSearchLoader();
  observeScrollToAction();
  initColorCheckboxes();
  initOutOfStockHandler();
  trackFilter();
};
