/* eslint-disable max-lines-per-function */
import { sendDataLayer, track } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";

const trackScroll = (elementClass, direction) => {
  const callback = () => {
    track("top_selling_brands_carousel_clicked", {
      direction
    });
  };

  onEvent("click", elementClass, callback, true);
};

export default () => {
  const $brandLinks = document.querySelectorAll(".js-brand-link");

  $brandLinks.forEach($link => {
    $link.addEventListener("click", () => {
      const { brandLabel } = $link.dataset;

      sendDataLayer({
        genericEvent: true,
        eventCategory: "Brands Slider",
        event: "click",
        eventLabel: brandLabel
      });

      track("top_selling_brands_link_clicked", {
        brand_name: brandLabel,
        url: $link.href,
        position: "relative"
      });
    });
  });

  trackScroll(".js-product-brand-scroll-left", "left");
  trackScroll(".js-product-brand-scroll-right", "right");
};
