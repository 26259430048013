/* eslint-disable max-lines */
import { redirectTo } from "common/utils/browser";
import { logUploadErrorEvent } from "common/utils/mixpanel/dropzone-error-event-tracker";
import { logUploadError } from "common/utils/honeybadger/log-upload-error";
import { api } from "jiffy-api";
import { submitDtfEvent, submitCompletedEvent } from "common/utils/mixpanel/submit-dtf-events";
import ProductDetailsDtfProgress from "components/jiffy/product-details-dtf-sheet/product-details-dtf-progress/product-details-dtf-progress";
import { isFileTypeSupported, logUnsupportedFileTypeEvent } from "common/file-type-helper";
import "./product-card-transfers.scss";

class ProductCardTransfer {
  constructor(catalogNumber, printAsIs, businessLine) {
    this.catalogNumber = catalogNumber;
    this.printAsIs = printAsIs;
    this.businessLine = businessLine;

    this.uploadType = null;
    this.uploadedFile = null;
    this.messageIndex = 0;
    this.percentage = 0;
    this.timeoutInterval = null;

    this.$container = document.getElementById(`js-product-card-transfers--${catalogNumber}`);
    this.analyticsPageName = this.$container?.dataset?.pageName || "category";

    this.progressBar = new ProductDetailsDtfProgress(
      this.$container,
      this.progressReport.bind(this)
    );

    this.handleElementClick = this.handleElementClick.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.updateProgressText = this.updateProgressText.bind(this);
    this.resetUploadMessages = this.resetUploadMessages.bind(this);
  }

  init() {
    [this.$fileInput, this.$progressTextContainer, this.$errorContainer] = this.getDOMElements();

    this.$fileInput.addEventListener("change", this.handleFileInputChange);
    this.$container.addEventListener("click", this.handleElementClick);
    this.$container.addEventListener("dragover", this.handleDragOver);
    this.$container.addEventListener("dragenter", this.handleDragEnter);
  }

  getDOMElements() {
    return [
      this.$container.querySelector('input[type="file"]'),
      this.$container.querySelector(".js-product-card-progress-text"),
      this.$container.querySelector(".js-product-card-error")
    ];
  }

  handleFileInputChange(e) {
    const [file] = e.target.files;
    if (!file) return;

    const { valid, message } = isFileTypeSupported(file, this.printAsIs);
    if (!valid) {
      this.setErrorMessage(message);
      logUnsupportedFileTypeEvent(file, {
        page: "transfers",
        pdp_type: "",
        business_line: this.businessLine
      });
      return;
    }

    this.uploadedFile = file;
    this.uploadType = "browse";
    this.uploadArtwork();
  }

  handleElementClick(e) {
    e.stopPropagation();
    this.trackAddFileAreaClick();
  }

  handleDragOver(e) {
    this.$container.classList.add("product-card-transfers--dropping");
    e.preventDefault();
  }

  handleDragEnter() {
    this.$container.classList.add("product-card-transfers--dropping");

    this.$container.addEventListener("dragleave", this.handleDragLeave);
    this.$container.addEventListener("drop", this.handleDrop);
  }

  handleDragLeave() {
    this.$container.classList.remove("product-card-transfers--dropping");

    this.$container.removeEventListener("dragleave", this.handleDragLeave);
    this.$container.removeEventListener("drop", this.handleDrop);
  }

  handleDrop(e) {
    e.preventDefault();
    if (!e.dataTransfer.files || !e.dataTransfer.files.length) return;

    [this.uploadedFile] = e.dataTransfer.files;
    if (!this.uploadedFile) return;

    const { valid, message } = isFileTypeSupported(this.uploadedFile, this.printAsIs);
    if (!valid) {
      this.setErrorMessage(message);
      logUnsupportedFileTypeEvent(this.uploadedFile, {
        page: "transfers",
        pdp_type: "",
        business_line: this.businessLine
      });
      return;
    }
    this.uploadType = "drop";
    this.uploadArtwork();

    this.$container.removeEventListener("dragleave", this.handleDragLeave);
    this.$container.removeEventListener("drop", this.handleDrop);
  }

  trackAddFileAreaClick() {
    submitDtfEvent("transfer_choose_file_clicked", {
      page: this.analyticsPageName,
      catalog_number: this.catalogNumber,
      business_line: this.businessLine
    });
  }

  trackUploadStarted() {
    submitDtfEvent("image_upload_started", {
      page: this.analyticsPageName,
      catalog_number: this.catalogNumber,
      type: this.uploadType,
      file_name: this.uploadedFile.name,
      file_type: this.uploadedFile.type,
      file_size: this.uploadedFile.size,
      dpi: 300,
      project_id: null
    });
  }

  sendUploadCompletedEventOnError(e, uploadStartTime) {
    // e is an Axios object
    const errorMessage = e.response.data.error;
    const artwork = {
      filetype: this.uploadedFile.type,
      filesize: this.uploadedFile.size,
      errors: [errorMessage]
    };

    this.trackUploadCompleted(artwork, uploadStartTime);
  }

  trackUploadCompleted(artwork, uploadStartTime) {
    if (!artwork) return;

    submitCompletedEvent({
      file: this.uploadedFile,
      catalogNumber: this.catalogNumber,
      uploadedOn: this.analyticsPageName,
      multiArtwork: false,
      validationErrors: artwork.errors,
      uploadStartTime,
      artwork
    });
  }

  startProgressBar() {
    this.progressBar.setFilename(this.uploadedFile.name);
    this.progressBar.start();
  }

  setProgressBarValue(progress) {
    this.progressBar.setValues(progress);
  }

  stopProgressBar() {
    this.progressBar.stop();
  }

  hideProgressBar() {
    this.progressBar.hide();
  }

  updateProgressText(text) {
    this.$progressTextContainer.textContent = text;
  }

  setErrorMessage(text) {
    this.$errorContainer.textContent = text;
  }

  processError(e) {
    const errorResponse = e.response?.data;
    const handledError = ["backend_file_validation", "backend_processing_error"].includes(
      errorResponse?.error_type
    );
    const errorMessage = errorResponse?.error || e.message;
    let uploadError;

    if (errorMessage === "Network Error") {
      uploadError = this.percentage === 100 ? "Processing error" : "Upload error";
    }

    logUploadError({
      error_message: errorMessage,
      error: e.message,
      handledError,
      uploadError,
      errorResponse
    });

    const pageInfo = {
      pageValue: this.analyticsPageName,
      businessLine: this.businessLine
    };
    logUploadErrorEvent(pageInfo, this.uploadedFile, e);

    this.$container.classList.remove("product-card-transfers--uploading");

    this.$fileInput.value = "";
    this.updateProgressText("");
    this.setErrorMessage(uploadError || errorMessage);
    this.resetUploadMessages();
  }

  progressReport(percentage) {
    this.percentage = percentage;

    if (percentage === 100 && !this.timeoutInterval) {
      this.updateProgressText("Uploading file");
    }
  }

  startUploadUI() {
    this.$container.classList.add("product-card-transfers--uploading");
    this.$container.classList.remove("product-card-transfers--dropping");
    this.startProgressBar();
  }

  toggleOtherDropzones(toEnabled) {
    const $otherContainers = document.querySelectorAll(
      `.js-product-card-transfers:not(#${this.$container.id})`
    );

    $otherContainers.forEach($container => {
      $container.classList.toggle("product-card-transfers--unavailable", !toEnabled);
      const fileInput = $container.querySelector('input[type="file"]');
      fileInput.disabled = !toEnabled;
    });
  }

  async uploadArtwork() {
    this.startUploadUI();
    this.toggleOtherDropzones(false);
    ProductCardTransfer.resetAllErrorMessages();
    ProductCardTransfer.toggleProductLinks(false);
    this.percentage = 0;

    const formData = new FormData();
    formData.append("generate_link", true);
    formData.append("file", this.uploadedFile);
    formData.append("catalog_number", this.catalogNumber);

    const uploadStartTime = performance.now();

    try {
      this.trackUploadStarted();
      this.updateProgressText("Uploading...");
      const response = await api.dtf.uploadArtwork(formData, {
        raw: true,
        headers: { "Content-Type": undefined },
        useAxiosForRequest: true,
        onUploadProgress: ({ progress }) => {
          this.setProgressBarValue(progress * 100);
        }
      });

      this.trackUploadCompleted(response, uploadStartTime);
      this.resetUploadMessages();
      this.hideProgressBar();

      this.updateProgressText("Redirecting...");
      redirectTo(response.redirect_url);
    } catch (e) {
      this.sendUploadCompletedEventOnError(e, uploadStartTime);
      this.processError(e);
      this.stopProgressBar();
      ProductCardTransfer.toggleProductLinks(true);
    } finally {
      this.toggleOtherDropzones(true);
    }
  }

  resetUploadMessages() {
    this.updateProgressText("");
    if (this.timeoutInterval) {
      clearTimeout(this.timeoutInterval);
      this.timeoutInterval = null;
    }
  }

  resetAllStates() {
    this.$container.classList.remove("product-card-transfers--uploading");
    this.hideProgressBar();
    this.updateProgressText("");
    this.$fileInput.value = "";
  }

  static resetAllErrorMessages() {
    document.querySelectorAll(".js-product-card-error").forEach($error => {
      // eslint-disable-next-line no-param-reassign
      $error.textContent = "";
    });
  }

  static toggleProductLinks(enable) {
    const $productCards = document.querySelectorAll(".product-card");
    $productCards.forEach($productCard => {
      $productCard.classList.toggle("product-card--disabled", !enable);
    });
  }
}

export default () => {
  const $rootElements = document.querySelectorAll(".js-product-card-transfers");
  const productCardTransfers = [];
  $rootElements.forEach($element => {
    const productCardTransfer = new ProductCardTransfer(
      $element.dataset.catalogNumber,
      $element.dataset.printAsIs === "true",
      $element.dataset.businessLine
    );
    productCardTransfer.init();
    productCardTransfers.push(productCardTransfer);
  });

  // workaround for safari's bfcache
  window.addEventListener("pageshow", e => {
    if (!e.persisted) return;

    ProductCardTransfer.toggleProductLinks(true);
    productCardTransfers.forEach(productCardTransfer => {
      productCardTransfer.resetAllStates();
    });
  });
};
