import { isIOS } from "common/utils/browser";

document.addEventListener("DOMContentLoaded", () => {
  if (isIOS()) return;

  // Catch the first input in the document if it exists
  const firstInput = document.querySelector("[data-autofocus=true]");
  if (firstInput) {
    firstInput.focus();
  }
});
