import { sendDataLayer } from "jiffy-analytics";
import PopupFactory, { initBadges } from "common/utils/ui/popup-factory";
import "./j1-badge-popup.scss";

window.badgesTimeout = window.badgesTimeout || null;

const SELECTORS = {
  BADGE: ".js-jiffy-first-badge",
  POPUP: ".js-jiffy-first-badge-popup",
  POPUP_TAIL: ".js-jiffy-first-badge-popup-tail",
  POPUP_CLOSE: ".js-fast-delivery-information-toggle-close",
  POPUP_LINK: "#j1-popup-link"
};

const sendGAEvent = data => {
  sendDataLayer({
    genericEvent: true,
    eventCategory: data.eventCategory || "PLP Filters",
    event: "Open",
    eventLabel: data.eventLabel || "Jiffy_1st_Tooltip_Shows"
  });
};

const customOffsets = {
  top: 83,
  tail_top: 53
};

// additional function for the only badges initialization
// that appears in DOM after some events
// it skips document listeners to avoid unnecessary actions
const initJ1stBadges = () => {
  initBadges(SELECTORS, customOffsets, sendGAEvent);
};

export { initJ1stBadges as initBadges };
export default () => {
  PopupFactory(SELECTORS, customOffsets, sendGAEvent);
};
