/* eslint-disable max-lines-per-function, max-lines */
import { EventBus, Events, store } from "jiffy-store";
import { api } from "jiffy-api";
import { fillMinimalPricesPCP } from "components/jiffy/product-color-picker/product-color-picker-loading-data";
import initSoftnessScore from "components/compositions/softness_score/component";
import { track } from "jiffy-analytics";
import postponeRendering from "common/utils/web-vitals";
import ProductColorPickerVisibilityClass from "./product-color-picker-visibility-class";
import ProductPrintCompositionsClass from "./product-print-compositions-class";
import ProductColorPickerModalClass from "./product-color-picker-modal-class";
import ProductColorPickerTrackElementsClass from "./product-color-picker-track-elements-class";
import PrintOptionsAnimationClass from "./print-options-animation-class";
import "./product-color-picker.scss";

const productColorPickerTrackElements = new ProductColorPickerTrackElementsClass();
const getTrackElements = productColorPickerTrackElements.getTrackElements.bind(
  productColorPickerTrackElements
);

const pcpModal = new ProductColorPickerModalClass("#products-color-popup", {
  modalBodySelector: ".js-product-color-picker-pdp-dynamic",
  spinnerSelector: ".js-product-color-picker-placeholder",
  productsSlidesSelector: ".js-color-variant-selector",
  scrollWrapperSelector: ".modal-wrapper__modal-body",
  getTrackElements
});
let printCompositionLink = null;

// eslint-disable-next-line no-new
new PrintOptionsAnimationClass(
  ".js-print-options-animation",
  ".js-print-options-animation-toggle",
  "open-with-animation"
);

const getPrintCompositionLink = () => {
  if (!printCompositionLink) {
    printCompositionLink = new ProductPrintCompositionsClass(
      ".js-product-color-picker-print-link",
      { getTrackElements }
    );
  }

  return printCompositionLink;
};

const materialVisibilityControl = new ProductColorPickerVisibilityClass(
  ".js-product-color-picker-hide-material",
  ".js-product-color-picker-material-container",
  {
    toggleVisibilityClassName: "hidden",
    getTrackElements,
    eventCallback: checked =>
      getPrintCompositionLink().setPrintParamToLink("hide_materials", checked)
  }
);

const infoVisibilityControl = new ProductColorPickerVisibilityClass(
  ".js-product-color-picker-hide-info",
  ".product-color-picker",
  {
    toggleVisibilityClassName: "product-color-picker--hidden-prices",
    getTrackElements,
    eventCallback: checked => getPrintCompositionLink().setPrintParamToLink("hide_prices", checked)
  }
);

const softnessVisibilityControl = new ProductColorPickerVisibilityClass(
  ".js-product-color-picker-hide-cozyfeel",
  ".js-product-color-picker-softness-container",
  {
    toggleVisibilityClassName: "hidden",
    getTrackElements,
    eventCallback: checked =>
      getPrintCompositionLink().setPrintParamToLink("hide_softness", checked),
    checkboxParentSelector: ".js-checkbox-parent"
  }
);

const brandVisibilityControl = new ProductColorPickerVisibilityClass(
  ".js-product-color-picker-hide-brand",
  ".js-product-color-picker-brand-container",
  {
    toggleVisibilityClassName: "hidden",
    getTrackElements,
    eventCallback: checked => getPrintCompositionLink().setPrintParamToLink("hide_brand", checked),
    checkboxParentSelector: ".js-checkbox-parent"
  }
);

const resetControls = () => {
  infoVisibilityControl.reset();
  materialVisibilityControl.reset();
  softnessVisibilityControl.reset();
  brandVisibilityControl.reset();
};

const updateComponents = productId => {
  getPrintCompositionLink().updateProductId(productId);
  materialVisibilityControl.updateButtonVisibility();
  infoVisibilityControl.updateButtonVisibility();
  softnessVisibilityControl.updateButtonVisibility();
  brandVisibilityControl.updateButtonVisibility();
  pcpModal.sendOpenMixpanelEvent();
};

const addPDPColorPickerEvent = () => {
  EventBus.on(Events.PRODUCT_STATIC_COLOR_SELECTED, () => {
    const productId = document.getElementById("product-page-container").dataset.realProductId;
    const $staticColorPicker = document.querySelector(".js-product-color-picker-pdp-static");
    const $dynamicColorPicker = document.querySelector(".js-product-color-picker-pdp-dynamic");

    $staticColorPicker?.classList.remove("hidden");
    $dynamicColorPicker?.classList.add("hidden");
    // Trying to spoof INP by finishing the click handler asap, modal will be opened when event loop is idle
    postponeRendering(() => pcpModal.openModal());

    // Init softness score
    initSoftnessScore();

    // Update Controls
    productColorPickerTrackElements.updateTrackElements({}); // We don't track events for PDP
    updateComponents(productId);
  });
};

const addColorPickerEventOnce = () => {
  if (EventBus.hasEventListener(Events.TOGGLE_PRODUCT_COLOR_PICKER)) return;
  EventBus.on(Events.TOGGLE_PRODUCT_COLOR_PICKER, async options => {
    const { productId, color } = options.target.dataset;
    const $staticColorPicker = document.querySelector(".js-product-color-picker-pdp-static");
    const $dynamicColorPicker = document.querySelector(".js-product-color-picker-pdp-dynamic");

    $staticColorPicker?.classList.add("hidden");
    // Prepare Modal and show it
    pcpModal.showSpinner().updateAccessibility(true).openModal(resetControls);

    // Fetch content
    const { html } = await api.product.compositionsPath({ productId, ac: color });
    const $pcp = document.createElement("div");
    $pcp.innerHTML = html.trim();

    // Fill Modal with content
    $dynamicColorPicker?.classList.remove("hidden");
    // Fill Modal with content when event loop is idle
    postponeRendering(() =>
      pcpModal.fillContent($pcp.firstChild).hideSpinner().updateAccessibility(false)
    );

    // Init softness score
    initSoftnessScore();

    // Update Controls
    const $productItem = options.target.closest(".js-products__item");
    const $lineItem = options.target.closest(".js-item-details-tracker");
    productColorPickerTrackElements.updateTrackElements({ $productItem, $lineItem });
    updateComponents(productId);
  });
};

export const listenForColorSelect = onSelect => {
  const ITEM_CLASS_NAMES = [
    "product-color-picker__item",
    "product-color-picker__item-image",
    "product-color-picker__item-name",
    "product-color-picker__item-sizes"
  ];

  document.addEventListener("click", event => {
    if (event.target && ITEM_CLASS_NAMES.indexOf(event.target.className) !== -1) {
      onSelect(event);

      const $linkSelector = event.target.closest(".js-color-variant-selector");
      const { $lineItem } = getTrackElements();
      if ($lineItem) {
        track("product_clicked", {
          version: "2",
          brand: $lineItem.dataset?.brand,
          click_context: "Color Modal",
          fulfillment_group: $lineItem.dataset?.fulfillmentGroup,
          page_name: $lineItem.dataset?.pageName,
          composition: $linkSelector.dataset?.color,
          spree_composition_id: $linkSelector.dataset?.id,
          business_line: $lineItem.dataset?.businessLine
        });
      }
    }
  });
};

const sizesStockHandler = () => {
  EventBus.on(Events.PRODUCT_INVENTORY_LOADED, () => {
    const variantCounts = window.inventory;
    if (!variantCounts) return;

    const $colorPicker = document.querySelector(".js-product-color-picker-pdp");
    $colorPicker.querySelectorAll(".js-product-color-picker-item-size").forEach(colorSize => {
      const { variantId } = colorSize.dataset || {};
      if (variantCounts[variantId] === 0) {
        colorSize.classList.add("product-color-picker__item-size--no-stock");
      }
    });
    $colorPicker
      .querySelectorAll(".product-color-picker__item-sizes-loading")
      .forEach(spinner => spinner.classList.add("hidden"));
    $colorPicker
      .querySelectorAll(".product-color-picker__item-sizes-row")
      .forEach(row => row.classList.remove("product-color-picker__item-sizes-row--hidden"));
  });
};

export default () => {
  store.pdp.changed((_, { data }) => {
    if (data.loadedPrices) {
      fillMinimalPricesPCP(data.loadedPrices);
    }
  });

  addColorPickerEventOnce();
  addPDPColorPickerEvent();
  sizesStockHandler();
};
