/* eslint-disable max-lines-per-function */
import "./canada-redirect-modal.scss";

const initRedirectModalCanada = () => {
  const redirectModal = document.querySelector("#canada-redirect-modal");
  function saveStoreCorrectionAt(onComplete) {
    $.ajax({
      url: "api/store_preference",
      type: "POST",
      beforeSend(xhr) {
        xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content"));
      },
      complete: onComplete
    });
  }

  if (redirectModal) {
    if (redirectModal.dataset.showModal === "true") {
      $(redirectModal).modal("show");
    }

    $(redirectModal).on("hide.bs.modal", saveStoreCorrectionAt);

    $(".redirect-modal__button").click($event => {
      $event.preventDefault();
      saveStoreCorrectionAt(() => {
        window.location.href = $event.target.parentElement.href;
      });
    });
  }
};

window.initRedirectModalCanada = initRedirectModalCanada;
