export const createInput = (type, name, value) => {
  const input = document.createElement("input");
  input.type = type;
  input.name = name;
  input.value = value;

  return input;
};

export default createInput;
