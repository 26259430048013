export const MODULE_STATE = Object.freeze({
  INIT: "init",
  LOADED: "loaded"
});

export const CRITICAL_MODULES = ["app", "assets"];

export const BUSINESS_LINE = Object.freeze({
  DTF: "Transfers",
  STICKER: "Stickers",
  SPANGLE: "Spangles"
});
