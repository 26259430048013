export const isVisible = el =>
  el &&
  typeof el.getBoundingClientRect === "function" &&
  el.getBoundingClientRect().top + window.scrollY <= window.innerHeight + window.scrollY;

export const isStyleVisible = $element => $element.offsetParent !== null;

export const stopVideo = el => {
  const iframe = el.querySelector("iframe");
  const video = el.querySelector("video");
  if (iframe) {
    const iframeSrc = iframe.src;
    iframe.src = iframeSrc;
  }
  if (video) {
    video.pause();
  }
};

/**
 * Auto-resize textarea until maxLines is reached
 * @param el textarea element
 * @param maxLines
 */
export function autoResizeTextArea(el, { maxLines } = {}) {
  const style = window.getComputedStyle(el);
  const unpreparedLineHeight = style.getPropertyValue("line-height");
  const lineHeightPx =
    unpreparedLineHeight === "normal"
      ? 1.15 * parseFloat(style.getPropertyValue("font-size")) // almost matches line-height: normal calculation
      : parseFloat(unpreparedLineHeight);

  const topBottomBorderWidths =
    parseFloat(style.getPropertyValue("border-top-width")) +
    parseFloat(style.getPropertyValue("border-bottom-width"));
  let verticalPaddings = 0;
  if (style.getPropertyValue("box-sizing") === "border-box") {
    verticalPaddings +=
      parseFloat(style.getPropertyValue("padding-top")) +
      parseFloat(style.getPropertyValue("padding-bottom")) +
      topBottomBorderWidths;
  }
  const oldScrollbarWidth = el.style.scrollbarWidth;
  // eslint-disable-next-line
  el.setAttribute("rows", "1");
  // eslint-disable-next-line
  el.style.height = "";
  // eslint-disable-next-line
  el.style.scrollbarWidth = "none";
  const scrollHeight = el.scrollHeight + topBottomBorderWidths;
  const newHeightPx = Math.max(
    Math.min(scrollHeight, maxLines > 0 ? lineHeightPx * maxLines + verticalPaddings : Infinity)
  );
  // eslint-disable-next-line
  el.style.scrollbarWidth = oldScrollbarWidth;
  if (!Number.isFinite(newHeightPx) || newHeightPx < 0) {
    return;
  }
  // eslint-disable-next-line
  el.style.height = `${newHeightPx}px`;
}

export const watchDomNodeChange = ($el, callback) => {
  const observer = new MutationObserver(mutationList => {
    callback($el, mutationList);
  });

  observer.observe($el, { childList: true, subtree: true, attributes: true, characterData: true });
  return observer;
};
