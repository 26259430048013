import "./mobile-menu-hercules.scss";
import { EventBus, Events } from "jiffy-store";
import { track } from "jiffy-analytics";

const $getHerculesButton = () =>
  document.querySelector(".js-masthead-hercules-hercules-icon-container");
const $getHerculesContainer = () => document.querySelector(".js-masthead-hercules");
const $getHerculesResultRoot = () => document.getElementById("js-hercules-result-container");
const $getHerculesMobileMenu = () => document.querySelector(".js-mobile-menu-hercules");

window.launchHerculesGPT = () => {
  EventBus.publish(Events.SHOW_HERCULES_CHAT);
};

export default () => {
  const $herculesContainer = $getHerculesResultRoot();
  const $herculesButton = $getHerculesButton();

  if (!$herculesContainer || !$herculesButton) return;

  $herculesButton.addEventListener("click", event => {
    // prevents redirecting to account page
    event.preventDefault();
    const sessionIdAvailable = $herculesContainer.dataset.uuid !== "";

    if (sessionIdAvailable) {
      EventBus.publish(Events.SHOW_HERCULES_CHAT);
      track("herculesgpt.accessed_via_masthead");
    }
  });

  EventBus.on(Events.CHAT_SESSION_RETRIEVED, (_, { sessionAvailable }) => {
    $getHerculesContainer().classList.toggle("masthead__hercules--disabled", !sessionAvailable);

    const $mobileMenu = $getHerculesMobileMenu();
    $mobileMenu.classList.toggle("mobile-menu-hercules__container--disabled", !sessionAvailable);
    $mobileMenu.disabled = !sessionAvailable;
  });
};
