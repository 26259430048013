/* eslint-disable no-param-reassign, max-lines-per-function */
import { initDropdownNavigation } from "common/jiffy/accessibility";
import "./dropdown-colors.scss";

const modifiers = {
  active: "dropdown-colors--active",
  documentLocked: "locked-mobile",
  trigger: "dropdown-active"
};

const selectors = {
  close: ".js-dropdown-colors-close",
  container: ".js-dropdown-colors-container",
  containerExternal: ".js-dropdown-colors-container-external",
  dropdown: ".js-dropdown-colors",
  trigger: ".js-dropdown-colors-trigger"
};

let scrollPosition = null;
const toggleScrollbar = state => {
  // update css variable
  document.documentElement.style.setProperty("--window-inner-height", `${window.innerHeight}px`);
  // save scroll position if the case
  if (state) scrollPosition = window.scrollY;
  // toggle scroll lock
  document.documentElement.classList.toggle(modifiers.documentLocked, state);
  // restore scroll position if the case
  if (!state && scrollPosition !== null) {
    window.scrollTo({ top: scrollPosition });
    scrollPosition = null;
  }
};

const closeAllInstances = () => {
  const $dropdowns = document.querySelectorAll(`${selectors.dropdown}.${modifiers.active}`);

  $dropdowns.forEach($dropdown => {
    const $trigger = $dropdown.previousElementSibling;
    $dropdown.classList.remove(modifiers.active);

    if ($trigger) {
      $trigger.ariaExpanded = "false";
      $trigger.blur();
      $trigger.classList.remove(modifiers.trigger);
    }
  });
};

const handleOutsideClick = ({ target }, { $container, $dropdown, $trigger }) => {
  const activeDropdowns = document.querySelectorAll(`.${modifiers.active}`).length;

  if (activeDropdowns && target !== $trigger && !$container.contains(target)) {
    $dropdown.classList.remove(modifiers.active);
    $trigger.ariaExpanded = "false";
    $trigger.classList.remove(modifiers.trigger);

    if (document.querySelectorAll(`.${modifiers.active}`).length === 0) {
      toggleScrollbar(false);
    }
  }
};

const getContainerNode = $trigger => {
  const {
    dataset: { externalContent },
    parentElement
  } = $trigger;

  if (externalContent) {
    return document.querySelector(externalContent);
  }

  return parentElement;
};

const getDropdownNode = $container => {
  const containerClassName = selectors.container.slice(1);

  if ($container.classList.contains(containerClassName)) {
    const dropdownClassName = selectors.dropdown.slice(1);
    return $container.querySelector(`.${dropdownClassName}`);
  }

  return null;
};

const clickHandler = $trigger => {
  const $container = getContainerNode($trigger);
  const $dropdown = getDropdownNode($container);

  if ($dropdown) {
    $trigger.addEventListener("click", e => {
      if (e.target.tagName === "A") e.preventDefault();

      if ($dropdown) {
        toggleScrollbar(true);
        $dropdown.classList.toggle(modifiers.active);
        $trigger.ariaExpanded = !($trigger.ariaExpanded === "true");
        $trigger.classList.toggle(modifiers.trigger);
      }
    });

    // handle outside clicks
    window.addEventListener("click", event => {
      handleOutsideClick(event, { $container, $dropdown, $trigger });
    });

    // handle close button
    const $closeTriggers = $dropdown.querySelectorAll(selectors.close);
    const handleCloseButton = () => {
      toggleScrollbar(false);
      $dropdown.classList.remove(modifiers.active);
      $trigger.ariaExpanded = "false";
      $trigger.classList.remove(modifiers.trigger);
    };
    $closeTriggers.forEach($closeTrigger =>
      $closeTrigger.addEventListener("click", handleCloseButton)
    );
  }
};

const hoverHandler = $trigger => {
  const $container = getContainerNode($trigger);
  const $dropdown = getDropdownNode($container);

  if ($dropdown) {
    // handle hover events
    $container.addEventListener("mouseenter", () => {
      closeAllInstances();
      $dropdown.classList.add(modifiers.active);
      $trigger.ariaExpanded = "true";
    });
    $container.addEventListener("mouseleave", () => {
      $dropdown.classList.remove(modifiers.active);
      $trigger.ariaExpanded = "false";
    });
  }
};

export default () => {
  const $triggers = document.querySelectorAll(selectors.trigger);
  if ($triggers.length === 0) return;

  $triggers.forEach($trigger => {
    const { eventName } = $trigger.dataset;

    if (eventName === "hover") {
      hoverHandler($trigger);
    } else if (eventName === "click" || eventName === undefined) {
      clickHandler($trigger);
    }
  });

  initDropdownNavigation({
    openClass: modifiers.active,
    triggerClass: selectors.trigger
  });
};
