export default class AppRegistryLoader {
  static async load(fn) {
    return new Promise(resolve => {
      document.addEventListener("DOMContentLoaded", async () => {
        await fn();
        resolve();
      });
    });
  }
}
