import { hideCurrentModal } from "common/utils/ui/modal";
import "./modal-wrapper.scss";

export default () => {
  document.addEventListener("keydown", event => {
    if (event.key === "Escape") {
      const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
      if (isNotCombinedKey) {
        hideCurrentModal();
      }
    }
  });
};
