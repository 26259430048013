import idleCallback from "common/utils/ui/idle_callback";

const HIDDEN_CLASSNAME = "hidden";

const createNavigationInitializer = ({
  navigationLinksSelector,
  navigationContentSelector,
  detailsContentSelector,
  activeLinkClassName
}) => {
  const $getNavigationLinks = () => document.querySelectorAll(navigationLinksSelector);
  const $getNavigationContent = () => document.querySelectorAll(navigationContentSelector);
  const $getDetailsContent = () => document.querySelectorAll(detailsContentSelector);

  const selectCategory = category => {
    const navigationLinks$ = $getNavigationLinks();
    const navigationContentElements$ = $getNavigationContent();
    const detailsContentElements$ = $getDetailsContent();

    const activeCategory = category || navigationContentElements$[0]?.dataset?.category;

    navigationLinks$.forEach(navLink => {
      navLink.classList.toggle(activeLinkClassName, navLink.dataset.category === activeCategory);
    });

    navigationContentElements$.forEach(content => {
      content.classList.toggle(HIDDEN_CLASSNAME, content.dataset.category !== activeCategory);
    });

    idleCallback(() => {
      detailsContentElements$.forEach(content => {
        content.classList.toggle(HIDDEN_CLASSNAME, content.dataset.category !== category);
      });
    });
  };

  const initNavigation = () => {
    $getNavigationLinks().forEach((link, _index) => {
      const listener = event => {
        const activeLink = event.target;
        const activeCategory = activeLink.dataset.category;
        selectCategory(activeCategory);
      };

      link.addEventListener("click", listener);
      link.addEventListener("mouseover", listener);
    });
  };

  return initNavigation;
};

export default createNavigationInitializer;
