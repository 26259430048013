export default class ProductColorPickerTrackElementsClass {
  constructor() {
    this.trackElements = {};
  }

  updateTrackElements(elements) {
    this.trackElements = elements;

    return this;
  }

  getTrackElements() {
    return this.trackElements;
  }
}
