import idleCallback, { timings } from "common/utils/ui/idle_callback";

export const initTooltip = () => {
  const $$body = $("body");
  const $$tooltip = $("[data-toggle='tooltip']");

  $$tooltip.tooltip({ delay: { hide: 200 } });

  $$body.on("click", '[data-toggle="tooltip"]', function handleTooltipTouch() {
    $(this).tooltip("show");
    $$tooltip.not(this).tooltip("hide"); // hide other popovers
    return false;
  });

  $$body.on("click touchstart", () => {
    $$tooltip.tooltip("hide"); // hide all popovers when
  });

  if ($(window).width() < 1024) {
    $('#product-input-grid-form [data-toggle="tooltip"]').tooltip("disable");
  }
};

const initTooltipOnLoad = () => {
  idleCallback(initTooltip, timings.tooltip);
};

export default initTooltipOnLoad;
