class IFrameMessenger {
  constructor(iframeSelector, urlOrigin = null) {
    this.iframeSelector = iframeSelector;
    this.urlOrigin = urlOrigin;
  }

  $getIframe() {
    return document.querySelector(this.iframeSelector);
  }

  getUrlOrigin() {
    if (this.urlOrigin) return this.urlOrigin;

    const $iframe = this.$getIframe();
    const { urlOrigin } = $iframe.dataset || {};
    return urlOrigin;
  }

  subscribe(callback) {
    window.addEventListener("message", this.callbackWrapper(callback), false);
  }

  unsubscribe(callback) {
    window.removeEventListener("message", this.callbackWrapper(callback), false);
  }

  callbackWrapper(callback) {
    return event => {
      if (event.origin !== this.getUrlOrigin()) return; // Security check

      callback(event);
    };
  }
}

export default IFrameMessenger;
