import { on as onEvent } from "common/utils/ui/event";
import "./component.scss";

const CLASS_NAMES = {
  SECTION_LINK: ".mobile-menu__section",
  DESKTOP: {
    ACTIVE_LINK: "masthead-transfers-navigation__section--active",
    HIDDEN_CONTENT: "hidden"
  },
  MOBILE: {
    SLIDE: ".js-masthead-transfers-slide",
    SLIDE_LINK: ".js-masthead-transfers-slide-link",
    EXPANDED_SLIDE: "masthead-transfers-navigation__slide--expanded",
    BACK: '.js-mobile-submenu-trigger[data-category="Transfers"]'
  }
};

const $getContainer = () => document.querySelector(".js-masthead-transfers-navigation");
const $getRootNavigation = () => document.querySelectorAll(".js-masthead-subsite-navigation");
const $getNavigationLinks = () =>
  document.querySelectorAll(".js-masthead-transfers-navigation-link");
const $getNavigationContent = () =>
  document.querySelectorAll(".js-masthead-transfers-navigation-content");
const $getSlides = () => document.querySelectorAll(CLASS_NAMES.MOBILE.SLIDE);

const selectCategory = category => {
  const activeCategory = category || Array.from($getNavigationContent())[0]?.dataset?.category;

  $getNavigationLinks().forEach(navLink => {
    navLink.classList.toggle(
      CLASS_NAMES.DESKTOP.ACTIVE_LINK,
      navLink.dataset.category === activeCategory
    );
  });

  $getNavigationContent().forEach(content => {
    content.classList.toggle(
      CLASS_NAMES.DESKTOP.HIDDEN_CONTENT,
      content.dataset.category !== activeCategory
    );
  });
};

export const initDekstopNavigation = () => {
  $getNavigationLinks().forEach((link, _index) => {
    const listener = event => {
      const activeLink = event.target;
      const activeCategory = activeLink.dataset.category;
      selectCategory(activeCategory);
    };

    link.addEventListener("click", listener);
    link.addEventListener("mouseover", listener);
  });

  $getRootNavigation().forEach(link => {
    link.addEventListener("mouseleave", event => {
      const activeLink = event.target;
      const activeCategory = activeLink.dataset.category;

      if (activeCategory === "Transfers") {
        selectCategory();
      }
    });
  });
};

export const initMobileNavigation = () => {
  onEvent("click", CLASS_NAMES.MOBILE.SLIDE_LINK, event => {
    const slide = event.target.closest(CLASS_NAMES.MOBILE.SLIDE);
    event.preventDefault();

    if (slide) {
      slide.classList.toggle(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);

      const container = $getContainer();
      const section = container?.closest(CLASS_NAMES.SECTION_LINK);

      if (container && section) {
        const { height } = container.getBoundingClientRect();
        const minHeight = slide.classList.contains(CLASS_NAMES.MOBILE.EXPANDED_SLIDE) ? height : 0;
        section.style.minHeight = `${minHeight}px`;
      }
    }
  });

  onEvent("click", CLASS_NAMES.MOBILE.BACK, event => {
    const section = event.target.closest(CLASS_NAMES.SECTION_LINK);

    if (section) {
      section.style.minHeight = "0";
    }

    $getSlides().forEach(slide => {
      slide.classList.remove(CLASS_NAMES.MOBILE.EXPANDED_SLIDE);
    });
  });
};
